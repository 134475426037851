import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-pink-600 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-lg w-full text-center">
        <div className="mb-6">
          <div className="relative w-32 h-32 mx-auto mb-4">
            {/* Animated money with eyes */}
            <div className="absolute inset-0 animate-bounce">
              <div className="relative w-full h-full bg-green-500 rounded-lg flex items-center justify-center transform -rotate-12">
                <span className="text-4xl">💸</span>
                <div className="absolute top-1/4 left-1/4 w-4 h-4 bg-white rounded-full">
                  <div className="absolute top-1/2 left-1/2 w-2 h-2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2 animate-pulse"></div>
                </div>
                <div className="absolute top-1/4 right-1/4 w-4 h-4 bg-white rounded-full">
                  <div className="absolute top-1/2 left-1/2 w-2 h-2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2 animate-pulse"></div>
                </div>
              </div>
            </div>
          </div>
          
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            Oops! Money Lost in Transit
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Looks like this debt got lost in the void! 
            <br />
            (Error 404: Page Not Found)
          </p>
        </div>

        <div className="space-y-6">
          <div className="bg-purple-50 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-purple-800 mb-3">
              Why am I seeing this? 🤔
            </h2>
            <ul className="text-purple-700 space-y-2 text-left">
              <li className="flex items-center">
                <span className="mr-2">💰</span>
                The debt might have been settled (lucky you!)
              </li>
              <li className="flex items-center">
                <span className="mr-2">🔍</span>
                The URL might be incorrect or outdated
              </li>
              <li className="flex items-center">
                <span className="mr-2">⏳</span>
                The content might have been moved or deleted
              </li>
            </ul>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 transition-colors duration-200"
            >
              🏠 Go Home
            </Link>
            <Link
              to="/dashboard"
              className="inline-flex items-center justify-center px-6 py-3 border border-purple-600 text-base font-medium rounded-md text-purple-600 bg-white hover:bg-purple-50 transition-colors duration-200"
            >
              📊 View Dashboard
            </Link>
          </div>

          <div className="mt-8 text-sm text-gray-500">
            <p>
              "Money isn't everything, but it's right up there with oxygen." 
              <br />
              - Zig Ziglar
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}