import React, { useState } from 'react';
import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';

export default function Footer() {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  return (
    <footer className="bg-white/10 backdrop-blur-md py-4 mt-auto">
      <div className="container mx-auto px-4 text-center text-white">
        <div className="mb-2">
          <button 
            onClick={() => setIsTermsOpen(true)}
            className="text-white hover:text-purple-200 mx-2 underline"
          >
            Terms & Agreements
          </button>
          <span className="mx-2">|</span>
          <button 
            onClick={() => setIsPrivacyOpen(true)}
            className="text-white hover:text-purple-200 mx-2 underline"
          >
            Privacy Policy
          </button>
        </div>
        <p>© Repaye 2024</p>
        <p className="text-sm opacity-75">Designed by Pattaya Creative Code</p>
      </div>

      <TermsModal 
        isOpen={isTermsOpen}
        onClose={() => setIsTermsOpen(false)}
      />
      
      <PrivacyModal 
        isOpen={isPrivacyOpen}
        onClose={() => setIsPrivacyOpen(false)}
      />
    </footer>
  );
}