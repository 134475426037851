import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DebtRequestModel } from '../db/models/DebtRequest';
import type { DebtRequest } from '../db/models/DebtRequest';
import UserModel from '../db/models/User';
import { debtCategories } from '../data/debtCategories';

interface DebtWithCreditor extends DebtRequest {
  creditorName?: string;
}

export default function PublicDebtCarousel() {
  const [debts, setDebts] = useState<DebtWithCreditor[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPublicDebts = async () => {
      try {
        const publicDebts = await DebtRequestModel.findPublicDebts(10);
        
        // Load creditor names
        const debtsWithCreditors = await Promise.all(
          publicDebts.map(async (debt: DebtRequest) => {
            try {
              const creditor = await UserModel.findById(debt.creditor_id);
              return {
                ...debt,
                creditorName: creditor?.full_name || 'Anonymous'
              };
            } catch (error) {
              return {
                ...debt,
                creditorName: 'Anonymous'
              };
            }
          })
        );
        
        setDebts(debtsWithCreditors);
      } catch (error) {
        console.error('Error loading public debts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPublicDebts();
  }, []);

  useEffect(() => {
    if (debts.length > 0) {
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % debts.length);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [debts.length]);

  const getCategoryEmoji = (category: string) => {
    return debtCategories[category]?.icon || '💰';
  };

  const getSubcategoryEmoji = (category: string, subcategory: string) => {
    const subcategoryData = debtCategories[category]?.subcategories.find(
      sub => sub.name === subcategory
    );
    return subcategoryData?.icon || '💰';
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (debts.length === 0) {
    return null;
  }

  return (
    <div className="relative overflow-hidden rounded-xl bg-white/10 backdrop-blur-sm p-1">
      <div className="relative rounded-lg overflow-hidden">
        <div 
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {debts.map((debt) => (
            <div
              key={debt.public_token}
              className="w-full flex-shrink-0 p-6"
            >
              <div className="bg-white/90 backdrop-blur-sm rounded-lg p-6 shadow-xl">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
                      {getCategoryEmoji(debt.category)} {debt.category}
                    </h3>
                    <p className="text-gray-600 flex items-center gap-2">
                      {getSubcategoryEmoji(debt.category, debt.subcategory)} {debt.subcategory}
                    </p>
                  </div>
                  <div className="flex flex-col items-end">
                    {debt.amount > 0 && (
                      <div className="text-2xl font-bold text-purple-600">
                        ${debt.amount.toFixed(2)}
                      </div>
                    )}
                    <div className="text-sm text-gray-500 mt-1">
                      by {debt.creditorName}
                    </div>
                  </div>
                </div>
                <p className="text-gray-700 mb-4">{debt.description}</p>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-gray-500">
                    {new Date(debt.created_at).toLocaleDateString()}
                  </span>
                  <Link
                    to={`/debt/${debt.public_token}`}
                    className="text-purple-600 hover:text-purple-700 font-medium flex items-center gap-2"
                  >
                    View Details 
                    <span className="text-lg" role="img" aria-label="View">
                      {debt.status === 'settled' ? '✅' : debt.status === 'cancelled' ? '❌' : '👉'}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {debts.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full transition-colors ${
                index === currentIndex ? 'bg-purple-600' : 'bg-purple-200'
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}