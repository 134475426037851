import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';

export interface LoginAttempt {
  id: string;
  email: string;
  ip_address: string;
  user_agent: string;
  success: boolean;
  location?: {
    country?: string;
    city?: string;
    region?: string;
  };
  created_at: string;
}

export class LoginAttemptModel {
  static async create(data: Omit<LoginAttempt, 'id' | 'created_at'>): Promise<LoginAttempt> {
    try {
      const { data: attempt, error } = await supabase
        .from('login_attempts')
        .insert([data])
        .select()
        .single();

      if (error) throw error;
      if (!attempt) throw new Error('Failed to create login attempt record');

      return attempt;
    } catch (error) {
      errorLogger.log(error as Error, 'LoginAttempt Create Error');
      throw error;
    }
  }

  static async getAll(limit: number = 100): Promise<LoginAttempt[]> {
    try {
      const { data, error } = await supabase
        .from('login_attempts')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'LoginAttempt GetAll Error');
      throw error;
    }
  }

  static async getByEmail(email: string): Promise<LoginAttempt[]> {
    try {
      const { data, error } = await supabase
        .from('login_attempts')
        .select('*')
        .eq('email', email)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'LoginAttempt GetByEmail Error');
      throw error;
    }
  }
}