import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';
import type { DebtRequest } from './DebtRequest';

export interface AdminTransactionActions {
  getAllTransactions: () => Promise<DebtRequest[]>;
  updateTransaction: (transactionId: string, updates: Partial<DebtRequest>) => Promise<DebtRequest>;
  deleteTransaction: (transactionId: string) => Promise<void>;
}

class AdminTransactionModel implements AdminTransactionActions {
  async getAllTransactions(): Promise<DebtRequest[]> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .select(`
          *,
          creditor:users!debt_requests_creditor_id_fkey (
            email,
            full_name
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'Admin Get All Transactions Error');
      throw error;
    }
  }

  async updateTransaction(transactionId: string, updates: Partial<DebtRequest>): Promise<DebtRequest> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .update({ ...updates, updated_at: new Date().toISOString() })
        .eq('id', transactionId)
        .select()
        .single();

      if (error) throw error;
      if (!data) throw new Error('Failed to update transaction');
      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'Admin Update Transaction Error');
      throw error;
    }
  }

  async deleteTransaction(transactionId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('debt_requests')
        .delete()
        .eq('id', transactionId);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'Admin Delete Transaction Error');
      throw error;
    }
  }
}

export const adminTransactionModel = new AdminTransactionModel();