import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';
import { emailService } from '../../services/EmailService';

export interface DebtRequest {
  id: string;
  creditor_id: string;
  debtor_name: string;
  debtor_email?: string;
  debtor_phone?: string;
  amount: number;
  description: string;
  category: string;
  subcategory: string;
  social_links?: string;
  visibility: 'private' | 'public';
  due_date?: string;
  status: 'pending' | 'settled' | 'cancelled';
  created_at: string;
  updated_at: string;
  public_token: string;
  secure_id: string;
  creditor?: {
    email: string;
    full_name: string;
  };
}

export interface DebtRequestCreate extends Omit<DebtRequest, 'id' | 'created_at' | 'updated_at' | 'status' | 'public_token' | 'secure_id'> {}

export class DebtRequestModel {
  private static generatePublicToken(): string {
    return Math.random().toString(36).substring(2, 15);
  }

  private static generateSecureId(): string {
    return `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;
  }

  private static isAdminId(id: string): boolean {
    return id === '00000000-0000-0000-0000-000000000000';
  }

  static async create(data: DebtRequestCreate): Promise<DebtRequest> {
    try {
      const publicToken = this.generatePublicToken();
      const secureId = this.generateSecureId();

      const { data: createdDebt, error } = await supabase
        .from('debt_requests')
        .insert([{
          ...data,
          public_token: publicToken,
          secure_id: secureId,
          status: 'pending'
        }])
        .select()
        .single();

      if (error) throw error;
      if (!createdDebt) throw new Error('Failed to create debt request');

      return createdDebt;
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest Create Error');
      throw error;
    }
  }

  static async findById(id: string): Promise<DebtRequest> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email, full_name)')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (!data) throw new Error(`Debt request with ID ${id} not found`);

      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest FindById Error');
      throw error;
    }
  }

  static async findByPublicToken(token: string): Promise<DebtRequest | null> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email, full_name)')
        .eq('public_token', token)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest FindByPublicToken Error');
      throw error;
    }
  }

  static async findPublicDebts(limit: number = 10): Promise<DebtRequest[]> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email, full_name)')
        .eq('visibility', 'public')
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest FindPublicDebts Error');
      throw error;
    }
  }

  static async findAll(): Promise<DebtRequest[]> {
    try {
      const { data, error } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email, full_name)')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest FindAll Error');
      throw error;
    }
  }

  static async findByCreditor(creditorId: string): Promise<DebtRequest[]> {
    try {
      // If admin, return all debt requests
      if (this.isAdminId(creditorId)) {
        return this.findAll();
      }

      // For regular users, return only their debt requests
      const { data, error } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email, full_name)')
        .eq('creditor_id', creditorId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest FindByCreditor Error');
      throw error;
    }
  }

  static async updateStatus(id: string, status: DebtRequest['status']): Promise<void> {
    try {
      const { error } = await supabase
        .from('debt_requests')
        .update({
          status,
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest UpdateStatus Error');
      throw error;
    }
  }

  static async remove(id: string, reason: string): Promise<void> {
    try {
      const { data: claim, error: fetchError } = await supabase
        .from('debt_requests')
        .select('*, creditor:users!debt_requests_creditor_id_fkey(email)')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;
      if (!claim) throw new Error('Claim not found');

      // Update claim status
      const { error: updateError } = await supabase
        .from('debt_requests')
        .update({ 
          status: 'cancelled',
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (updateError) throw updateError;

      // Send email to creditor
      await emailService.sendClaimRemovedEmail(claim.creditor.email, {
        category: claim.category,
        subcategory: claim.subcategory,
        amount: claim.amount,
        description: claim.description,
        reason: reason
      });
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest Remove Error');
      throw error;
    }
  }

  static async delete(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('debt_requests')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest Delete Error');
      throw error;
    }
  }

  static async getStatistics(creditorId: string) {
    try {
      let debts;
      
      // If admin, get statistics for all debt requests
      if (this.isAdminId(creditorId)) {
        const { data, error } = await supabase
          .from('debt_requests')
          .select('status, amount');

        if (error) throw error;
        debts = data;
      } else {
        // For regular users, get statistics for their debt requests only
        const { data, error } = await supabase
          .from('debt_requests')
          .select('status, amount')
          .eq('creditor_id', creditorId);

        if (error) throw error;
        debts = data;
      }

      const stats = {
        total: debts?.length || 0,
        settled: debts?.filter(d => d.status === 'settled').length || 0,
        pending: debts?.filter(d => d.status === 'pending').length || 0,
        amountPending: debts?.filter(d => d.status === 'pending').reduce((sum, d) => sum + (d.amount || 0), 0) || 0,
        amountSettled: debts?.filter(d => d.status === 'settled').reduce((sum, d) => sum + (d.amount || 0), 0) || 0
      };

      return {
        ...stats,
        successRate: stats.total > 0 ? Math.round((stats.settled / stats.total) * 100) : 0
      };
    } catch (error) {
      errorLogger.log(error as Error, 'DebtRequest GetStatistics Error');
      throw error;
    }
  }
}