import React, { useState } from 'react';
import LoginModal from './LoginModal';
import { useAuth } from '../contexts/AuthContext';

export default function Header() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { user, logout } = useAuth();

  return (
    <header className="bg-white/10 backdrop-blur-md py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="w-12 h-12 relative">
            <svg viewBox="0 0 100 100" className="w-full h-full">
              <circle cx="50" cy="50" r="45" fill="white" stroke="black" strokeWidth="5"/>
              <path d="M50,20 A30,30 0 0,1 80,50" fill="none" stroke="black" strokeWidth="5"/>
              <path d="M50,80 A30,30 0 0,1 20,50" fill="none" stroke="black" strokeWidth="5"/>
              <circle cx="35" cy="40" r="5" fill="black"/>
              <circle cx="65" cy="40" r="5" fill="black"/>
              <path d="M35,60 Q50,70 65,60" fill="none" stroke="black" strokeWidth="5"/>
              <path d="M20,50 L30,45 L30,55 Z" fill="black"/>
              <path d="M80,50 L70,45 L70,55 Z" fill="black"/>
            </svg>
          </div>
          <div>
            <h1 className="text-3xl font-bold text-white">REPAYE</h1>
            <p className="text-sm text-white/80">Repay as you earn.</p>
          </div>
        </div>
        
        {user ? (
          <button
            onClick={logout}
            className="bg-white/20 hover:bg-white/30 text-white font-semibold px-6 py-2 rounded-lg transition-all duration-300 backdrop-blur-sm flex items-center space-x-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span>Logout</span>
          </button>
        ) : (
          <button
            onClick={() => setIsLoginModalOpen(true)}
            className="bg-white/20 hover:bg-white/30 text-white font-semibold px-6 py-2 rounded-lg transition-all duration-300 backdrop-blur-sm flex items-center space-x-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            <span>Login</span>
          </button>
        )}

        <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
      </div>
    </header>
  );
}