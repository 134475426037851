import React from 'react';

export default function ProcessAnimation() {
  return (
    <div className="mb-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          {
            icon: "📝",
            title: "Fill the Form",
            description: "Tell us about who owes you and why",
            animation: "animate-bounce"
          },
          {
            icon: "🤝",
            title: "Share with Friends",
            description: "Let others know about their forgotten debts",
            animation: "animate-pulse"
          },
          {
            icon: "😌",
            title: "Settle the Debt",
            description: "Get it done once and for all",
            animation: "animate-ping"
          }
        ].map((step, index) => (
          <div 
            key={index}
            className="relative bg-white/20 backdrop-blur-sm rounded-xl p-6 text-center group hover:bg-white/30 transition-all duration-300"
          >
            <div className={`text-6xl mb-4 ${step.animation} inline-block`}>
              {step.icon}
            </div>
            <div className="absolute -right-4 top-1/2 hidden md:block text-white text-4xl transform -translate-y-1/2">
              {index < 2 ? "→" : ""}
            </div>
            <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
            <p className="text-white/90">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}