import React from 'react';

interface StatsCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  gradient: string;
}

export default function StatsCard({ title, value, icon, gradient }: StatsCardProps) {
  return (
    <div className={`bg-gradient-to-br ${gradient} overflow-hidden shadow rounded-lg text-white`}>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {icon}
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-white/80 truncate">{title}</dt>
              <dd className="text-2xl font-semibold">{value}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}