import React, { useState } from 'react';
import { debtCategories } from '../data/debtCategories';
import FacebookShare from './FacebookShare';
import { useNavigate } from 'react-router-dom';

export default function MainDebtForm() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [description, setDescription] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const [submittedData, setSubmittedData] = useState<any>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!termsAccepted) return;
    
    const data = {
      category: selectedCategory,
      subcategory: selectedSubcategory,
      description,
      termsAccepted
    };

    setSubmittedData(data);
    setShowShare(true);
  };

  const handleShareComplete = () => {
    navigate('/signup', { replace: true });
  };

  if (showShare) {
    return (
      <FacebookShare 
        debtData={submittedData}
        onShareComplete={handleShareComplete}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-xl p-8">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Debt Category
          </label>
          <div className="relative">
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-base"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedSubcategory('');
              }}
              required
            >
              <option value="">Choose a category...</option>
              {Object.entries(debtCategories).map(([category, data]) => (
                <option key={category} value={category}>
                  {data.icon} {category} - {data.explanation}
                </option>
              ))}
            </select>
          </div>
        </div>

        {selectedCategory && (
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Subcategory
            </label>
            <div className="relative">
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-base"
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                required
              >
                <option value="">Choose a subcategory...</option>
                {debtCategories[selectedCategory].subcategories.map((sub) => (
                  <option key={sub.name} value={sub.name}>
                    {sub.icon} {sub.name} - {sub.explanation}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Tell us about this debt..."
            required
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
          />
          <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
            I agree to the <a href="#" className="text-purple-600 hover:text-purple-700">Terms and Conditions</a>
          </label>
        </div>

        <button
          type="submit"
          disabled={!termsAccepted}
          className="w-full bg-purple-600 text-white font-bold py-3 px-4 rounded-md hover:bg-purple-700 transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Claim Your Debt Now! 💰
        </button>
      </form>
    </div>
  );
}