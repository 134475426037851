import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';

export interface VerificationCode {
  id: string;
  user_id: string;
  code: string;
  type: 'email' | 'phone';
  expires_at: string;
  created_at: string;
}

class VerificationCodeModel {
  static async generate(userId: string, type: 'email' | 'phone'): Promise<VerificationCode> {
    try {
      // Delete any existing codes for this user and type
      await supabase
        .from('verification_codes')
        .delete()
        .eq('user_id', userId)
        .eq('type', type);

      // Generate new code
      const code = type === 'email' 
        ? uuidv4()
        : Math.floor(100000 + Math.random() * 900000).toString();

      // Set expiration (24 hours for email, 10 minutes for phone)
      const expiresAt = new Date();
      expiresAt.setHours(expiresAt.getHours() + (type === 'email' ? 24 : 0));
      expiresAt.setMinutes(expiresAt.getMinutes() + (type === 'phone' ? 10 : 0));

      const { data, error } = await supabase
        .from('verification_codes')
        .insert([{
          user_id: userId,
          code,
          type,
          expires_at: expiresAt.toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      if (!data) throw new Error('Failed to create verification code');

      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'VerificationCode Generate Error');
      throw error;
    }
  }

  static async verify(userId: string, code: string, type: 'email' | 'phone'): Promise<boolean> {
    try {
      const { data: verificationCode, error } = await supabase
        .from('verification_codes')
        .select('*')
        .eq('user_id', userId)
        .eq('code', code)
        .eq('type', type)
        .gt('expires_at', new Date().toISOString())
        .single();

      if (error) throw error;

      if (verificationCode) {
        // Delete the used code
        await supabase
          .from('verification_codes')
          .delete()
          .eq('id', verificationCode.id);

        // Update user verification status
        const updateData = type === 'email' 
          ? { is_email_verified: true }
          : { is_phone_verified: true };

        await supabase
          .from('users')
          .update(updateData)
          .eq('id', userId);

        return true;
      }

      return false;
    } catch (error) {
      errorLogger.log(error as Error, 'VerificationCode Verify Error');
      throw error;
    }
  }
}

export default VerificationCodeModel;