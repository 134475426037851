import React, { useState, useEffect } from 'react';
import { AdminStatsModel } from '../../db/models/AdminStats';
import { VisitorAnalyticsModel } from '../../db/models/VisitorAnalytics';
import { LoginAttemptModel, type LoginAttempt } from '../../db/models/LoginAttempt';
import { DebtRequestModel, type DebtRequest } from '../../db/models/DebtRequest';
import { errorLogger } from '../../utils/errorLogger';
import DashboardStats from '../../components/admin/DashboardStats';
import AnalyticsSection from '../../components/admin/AnalyticsSection';
import LoginAttemptsTable from '../../components/admin/LoginAttemptsTable';
import ClaimsTable from '../../components/admin/ClaimsTable';

export default function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [stats, setStats] = useState({
    amountPending: 0,
    amountSettled: 0,
    totalUsers: 0,
    verifiedUsers: 0,
    pendingClaims: 0,
    settledClaims: 0
  });
  const [visitorStats, setVisitorStats] = useState({
    totalVisits: 0,
    uniqueVisitors: 0,
    countries: {},
    browsers: {},
    os: {},
    trafficSources: {},
    topReferrers: {}
  });
  const [loginAttempts, setLoginAttempts] = useState<LoginAttempt[]>([]);
  const [claims, setClaims] = useState<DebtRequest[]>([]);

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const [adminStats, analytics, attempts, allClaims] = await Promise.all([
        AdminStatsModel.getStats(),
        VisitorAnalyticsModel.getStats(),
        LoginAttemptModel.getAll(50),
        DebtRequestModel.findAll()
      ]);

      setStats(adminStats);
      setVisitorStats(analytics);
      setLoginAttempts(attempts);
      setClaims(allClaims);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load dashboard data';
      setError(errorMessage);
      errorLogger.log(err as Error, 'Admin Dashboard Load Error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveClaim = async (id: string, reason: string) => {
    try {
      await DebtRequestModel.remove(id, reason);
      await loadDashboardData(); // Refresh data
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to remove claim';
      setError(errorMessage);
      errorLogger.log(err as Error, 'Admin Remove Claim Error');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <DashboardStats stats={stats} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-6">Traffic Sources</h2>
          <AnalyticsSection
            title="Traffic Sources"
            data={visitorStats.trafficSources}
            total={visitorStats.totalVisits}
            color="purple"
          />
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-6">Browsers & OS</h2>
          <div className="space-y-6">
            <AnalyticsSection
              title="Browsers"
              data={visitorStats.browsers}
              total={visitorStats.totalVisits}
              color="blue"
            />
            <AnalyticsSection
              title="Operating Systems"
              data={visitorStats.os}
              total={visitorStats.totalVisits}
              color="green"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Recent Login Attempts</h2>
        </div>
        <LoginAttemptsTable attempts={loginAttempts} />
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">All Claims</h2>
        </div>
        <ClaimsTable claims={claims} onRemoveClaim={handleRemoveClaim} />
      </div>
    </div>
  );
}