import React, { useState } from 'react';
import { DebtRequest } from '../../db/models/DebtRequest';
import RemoveClaimModal from './RemoveClaimModal';

interface ClaimsTableProps {
  claims: DebtRequest[];
  onRemoveClaim: (id: string, reason: string) => Promise<void>;
}

export default function ClaimsTable({ claims, onRemoveClaim }: ClaimsTableProps) {
  const [selectedClaim, setSelectedClaim] = useState<DebtRequest | null>(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleRemove = (claim: DebtRequest) => {
    setSelectedClaim(claim);
    setIsRemoveModalOpen(true);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Creditor</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Debtor</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {claims.map((claim) => (
              <tr key={claim.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{claim.category}</div>
                  <div className="text-sm text-gray-500">{claim.subcategory}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {claim.creditor?.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{claim.debtor_name}</div>
                  <div className="text-sm text-gray-500">{claim.debtor_email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  ${claim.amount.toFixed(2)}
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900 max-w-xs truncate">
                    {claim.description}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    claim.status === 'settled'
                      ? 'bg-green-100 text-green-800'
                      : claim.status === 'cancelled'
                      ? 'bg-red-100 text-red-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {claim.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(claim.created_at).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleRemove(claim)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedClaim && (
        <RemoveClaimModal
          isOpen={isRemoveModalOpen}
          onClose={() => {
            setIsRemoveModalOpen(false);
            setSelectedClaim(null);
          }}
          onConfirm={async (reason) => {
            await onRemoveClaim(selectedClaim.id, reason);
            setIsRemoveModalOpen(false);
            setSelectedClaim(null);
          }}
          claim={selectedClaim}
        />
      )}
    </>
  );
}