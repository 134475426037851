import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import type { DebtRequest } from '../../db/models/DebtRequest';
import { useFacebookSDK } from '../../hooks/useFacebookSDK';

interface ShareDebtModalProps {
  isOpen: boolean;
  onClose: () => void;
  debt: DebtRequest;
}

export default function ShareDebtModal({ isOpen, onClose, debt }: ShareDebtModalProps) {
  const defaultMessage = `Hey ${debt.debtor_name}! 👋\n\n` +
    `Remember this? 🤔\n` +
    `"${debt.description}"\n\n` +
    `${debt.category === 'Personal Loans' ? `You owe me $${debt.amount.toFixed(2)}! 💰\n\n` : ''}` +
    `When are we gonna settle this? 🤝\n\n` +
    `Let's make it right through Repaye - the fun way to handle IOUs!\n` +
    `Check it out at: ${window.location.origin}/debt/${debt.public_token}\n\n` +
    `#Repaye #YouOweMe #SettleDebt ${debt.category.replace(/\s+/g, '')}`;

  const [customMessage, setCustomMessage] = useState(defaultMessage);
  const fbInitialized = useFacebookSDK();

  const [shareStatus, setShareStatus] = useState({
    facebook: false,
    twitter: false,
    instagram: false,
    tiktok: false,
    whatsapp: false,
    copyLink: false
  });

  const handleFacebookShare = async () => {
    if (!fbInitialized) {
      console.error('Facebook SDK not initialized');
      return;
    }

    try {
      // Create a Facebook post with link and message
      window.FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
          object: {
            'og:url': `${window.location.origin}/debt/${debt.public_token}`,
            'og:title': 'REPAYE - Friendly Debt Reminder',
            'og:description': customMessage,
            'og:image': 'https://repaye.com/og-image.jpg',
            'og:type': 'website'
          }
        })
      }, function(response: { error_message?: string; post_id?: string }) {
        if (response && !response.error_message) {
          setShareStatus(prev => ({ ...prev, facebook: true }));
        }
      });
    } catch (error) {
      console.error('Error sharing to Facebook:', error);
    }
  };

  const handleShare = async (platform: keyof typeof shareStatus) => {
    const shareUrl = `${window.location.origin}/debt/${debt.public_token}`;

    if (platform === 'copyLink') {
      try {
        await navigator.clipboard.writeText(`${customMessage}\n\n${shareUrl}`);
        setShareStatus(prev => ({ ...prev, [platform]: true }));
        setTimeout(() => setShareStatus(prev => ({ ...prev, [platform]: false })), 2000);
        return;
      } catch (err) {
        console.error('Failed to copy:', err);
        return;
      }
    }

    if (platform === 'facebook') {
      handleFacebookShare();
      return;
    }

    let url = '';
    switch (platform) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(customMessage)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'whatsapp':
        url = `https://wa.me/?text=${encodeURIComponent(customMessage + '\n\n' + shareUrl)}`;
        break;
      case 'instagram':
        await navigator.clipboard.writeText(customMessage + '\n\n' + shareUrl);
        alert('Message copied! Open Instagram and paste to share.');
        setShareStatus(prev => ({ ...prev, [platform]: true }));
        return;
      case 'tiktok':
        url = `https://www.tiktok.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(customMessage)}`;
        break;
    }

    if (url) {
      window.open(url, '_blank', 'width=600,height=400');
      setShareStatus(prev => ({ ...prev, [platform]: true }));
    }
  };

  const handleReset = () => {
    setCustomMessage(defaultMessage);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full rounded-2xl bg-white p-8 shadow-2xl">
          {/* Rest of the component remains the same */}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}