import { VisitorAnalyticsModel } from '../db/models/VisitorAnalytics';

export async function trackPageView(): Promise<void> {
  try {
    const visitorInfo = {
      ip_address: 'unknown', // Will be set by server
      user_agent: navigator.userAgent,
      referrer: document.referrer,
      referrer_type: getReferrerType(document.referrer),
      path: window.location.pathname,
      device_info: {
        browser: getBrowser(),
        os: getOS(),
        language: navigator.language,
        screen_resolution: `${window.screen.width}x${window.screen.height}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    await VisitorAnalyticsModel.create(visitorInfo);
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
}

function getReferrerType(referrer: string): 'direct' | 'search' | 'social' | 'other' {
  if (!referrer) return 'direct';

  try {
    const url = new URL(referrer);
    const domain = url.hostname.replace('www.', '');

    const SEARCH_ENGINES = [
      'google.com',
      'bing.com',
      'yahoo.com',
      'duckduckgo.com',
      'baidu.com',
      'yandex.com'
    ];

    const SOCIAL_PLATFORMS = [
      'facebook.com',
      'twitter.com',
      'instagram.com',
      'linkedin.com',
      'pinterest.com',
      'reddit.com',
      'youtube.com',
      't.co',
      'fb.me',
      'lnkd.in'
    ];

    if (SEARCH_ENGINES.some(engine => domain.includes(engine))) {
      return 'search';
    }

    if (SOCIAL_PLATFORMS.some(platform => domain.includes(platform))) {
      return 'social';
    }

    return 'other';
  } catch {
    return 'other';
  }
}

function getBrowser(): string {
  const ua = navigator.userAgent;
  if (ua.includes('Firefox/')) return 'Firefox';
  if (ua.includes('Chrome/')) return 'Chrome';
  if (ua.includes('Safari/')) return 'Safari';
  if (ua.includes('Edge/')) return 'Edge';
  if (ua.includes('MSIE ') || ua.includes('Trident/')) return 'Internet Explorer';
  return 'Unknown';
}

function getOS(): string {
  const ua = navigator.userAgent;
  if (ua.includes('Windows')) return 'Windows';
  if (ua.includes('Mac OS')) return 'macOS';
  if (ua.includes('Linux')) return 'Linux';
  if (ua.includes('Android')) return 'Android';
  if (ua.includes('iOS')) return 'iOS';
  return 'Unknown';
}