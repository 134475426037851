interface DebtCategory {
  subcategories: {
    name: string;
    explanation: string;
    icon: string;
  }[];
  explanation: string;
  icon: string;
}

export const debtCategories: Record<string, DebtCategory> = {
  "Personal Loans": {
    explanation: "Money borrowed between close relationships",
    icon: "👥💰",
    subcategories: [
      {
        name: "Friend Loans",
        explanation: "borrowed cash for small things",
        icon: "🤝"
      },
      {
        name: "Family Loans",
        explanation: "borrowed from a family member",
        icon: "👨‍👩‍👧‍👦"
      },
      {
        name: "Romantic Loans",
        explanation: "borrowed from a partner or ex",
        icon: "💕"
      },
      {
        name: "Roommate Loans",
        explanation: "for shared bills, rent, or groceries",
        icon: "🏠"
      }
    ]
  },
  "Everyday Expenses": {
    explanation: "Regular daily or weekly expenses shared with others",
    icon: "📅💵",
    subcategories: [
      {
        name: "Coffee or Lunches",
        explanation: "unpaid coffee dates, lunches",
        icon: "☕️🥪"
      },
      {
        name: "Utilities & Bills",
        explanation: "unpaid contributions to household bills",
        icon: "📱💡"
      },
      {
        name: "Groceries",
        explanation: "unpaid grocery share",
        icon: "🛒"
      }
    ]
  },
  "Shared Experiences": {
    explanation: "Expenses from activities done together",
    icon: "🎭🤝",
    subcategories: [
      {
        name: "Dinner or Drinks Out",
        explanation: "unpaid dinners, bar tabs",
        icon: "🍽️🍻"
      },
      {
        name: "Event Tickets",
        explanation: "concerts, movies, or sports tickets",
        icon: "🎫🎭"
      },
      {
        name: "Vacation Expenses",
        explanation: "contributions to a shared trip",
        icon: "✈️🏖️"
      }
    ]
  },
  "Subscription or Memberships": {
    explanation: "Shared digital and physical memberships",
    icon: "📱💳",
    subcategories: [
      {
        name: "Streaming Accounts",
        explanation: "Netflix, Spotify, etc",
        icon: "📺🎵"
      },
      {
        name: "Gym Memberships",
        explanation: "borrowed for someone's gym",
        icon: "💪"
      },
      {
        name: "Gaming Accounts",
        explanation: "for online games or services",
        icon: "🎮"
      }
    ]
  },
  "Favors Turned Debts": {
    explanation: "Services or help that was meant to be repaid",
    icon: "🤲💭",
    subcategories: [
      {
        name: "Pet Sitting",
        explanation: "unpaid favors for pet care",
        icon: "🐱🐕"
      },
      {
        name: "Transport or Gas",
        explanation: "rides or shared travel expenses",
        icon: "🚗⛽"
      },
      {
        name: "Moving Assistance",
        explanation: "borrowed help with moving items",
        icon: "📦💪"
      }
    ]
  },
  "Gifts and Borrowed Items": {
    explanation: "Physical items that were borrowed or shared",
    icon: "🎁📦",
    subcategories: [
      {
        name: "Unreturned Items",
        explanation: "tools, books, or gadgets",
        icon: "🔧📚"
      },
      {
        name: "Party Contributions",
        explanation: "missed contributions for parties or gifts",
        icon: "🎉🎈"
      },
      {
        name: "Clothing or Accessories",
        explanation: "borrowed clothes, jewelry, or bags",
        icon: "👕💍"
      }
    ]
  },
  "Loans for Emergencies": {
    explanation: "Urgent or unexpected expense assistance",
    icon: "🚨💰",
    subcategories: [
      {
        name: "Medical Expenses",
        explanation: "contributions to a friend's medical bills",
        icon: "🏥"
      },
      {
        name: "Car Repairs",
        explanation: "loaned money for car repair or gas",
        icon: "🚗🔧"
      },
      {
        name: "Home Repairs",
        explanation: "contributions to a friend's emergency home fix",
        icon: "🏠🔨"
      }
    ]
  },
  "Funny or Unique Debts": {
    explanation: "Light-hearted or unusual obligations",
    icon: "😅🎲",
    subcategories: [
      {
        name: "Bets and Wagers",
        explanation: "unpaid friendly bets",
        icon: "🎲🎯"
      },
      {
        name: "I'll Get You Next Time",
        explanation: "a general I.O.U. from frequent deferrals",
        icon: "🤞💫"
      },
      {
        name: "Drunk Promises",
        explanation: "debts from impulsive promises",
        icon: "🍺😅"
      }
    ]
  }
};