import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';

export interface AdminStats {
  totalUsers: number;
  verifiedUsers: number;
  pendingClaims: number;
  settledClaims: number;
  amountPending: number;
  amountSettled: number;
}

export class AdminStatsModel {
  static async getStats(): Promise<AdminStats> {
    try {
      // Get users stats
      const { data: users, error: usersError } = await supabase
        .from('users')
        .select('id, is_email_verified');
      
      if (usersError) throw usersError;

      // Get transactions stats
      const { data: transactions, error: transError } = await supabase
        .from('debt_requests')
        .select('amount, status');
      
      if (transError) throw transError;

      const totalUsers = users?.length || 0;
      const verifiedUsers = users?.filter(u => u.is_email_verified).length || 0;

      const pendingTransactions = transactions?.filter(t => t.status === 'pending') || [];
      const settledTransactions = transactions?.filter(t => t.status === 'settled') || [];

      const amountPending = pendingTransactions.reduce((sum, t) => sum + (t.amount || 0), 0);
      const amountSettled = settledTransactions.reduce((sum, t) => sum + (t.amount || 0), 0);

      return {
        totalUsers,
        verifiedUsers,
        pendingClaims: pendingTransactions.length,
        settledClaims: settledTransactions.length,
        amountPending,
        amountSettled
      };
    } catch (error) {
      errorLogger.log(error as Error, 'Admin Stats Error');
      throw error;
    }
  }
}