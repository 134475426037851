import React, { createContext, useContext, useState, useEffect } from 'react';
import { errorLogger } from '../utils/errorLogger';
import { supabase } from '../config/database';
import { LoginAttemptModel } from '../db/models/LoginAttempt';

interface User {
  id: string;
  email: string;
  full_name: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  created_at: string;
  role?: 'admin' | 'moderator' | 'support';
}

interface LoginResult {
  success: boolean;
  error?: string;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<LoginResult>;
  logout: () => void;
  resendVerification: (email: string) => Promise<boolean>;
  verifyEmail: (email: string, code: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    try {
      const savedUser = localStorage.getItem('auth_user');
      const token = localStorage.getItem('auth_token');
      
      if (savedUser && token) {
        const payload = JSON.parse(atob(token));
        if (payload.exp > Math.floor(Date.now() / 1000)) {
          setUser(JSON.parse(savedUser));
        } else {
          localStorage.removeItem('auth_user');
          localStorage.removeItem('auth_token');
        }
      }
    } catch (error) {
      errorLogger.log(error as Error, 'Auth Provider Init');
    }
  }, []);

  const login = async (email: string, password: string): Promise<LoginResult> => {
    try {
      // First, check if it's an admin/team member
      const { data: teamMember } = await supabase
        .from('team_members')
        .select('*')
        .eq('email', email)
        .eq('status', 'active')
        .single();

      if (teamMember) {
        // Verify admin password
        if (teamMember.password_hash !== btoa(password)) {
          await LoginAttemptModel.create({
            email,
            ip_address: 'unknown',
            user_agent: navigator.userAgent,
            success: false
          });
          return { success: false, error: 'Invalid password' };
        }

        // Create admin user data
        const userData: User = {
          id: teamMember.id,
          email: teamMember.email,
          full_name: teamMember.full_name,
          isEmailVerified: teamMember.email_verified,
          isPhoneVerified: true,
          created_at: teamMember.created_at,
          role: teamMember.role
        };

        // Generate token and set user
        const token = generateToken(userData);
        setUser(userData);
        localStorage.setItem('auth_user', JSON.stringify(userData));
        localStorage.setItem('auth_token', token);

        // Log successful attempt
        await LoginAttemptModel.create({
          email,
          ip_address: 'unknown',
          user_agent: navigator.userAgent,
          success: true
        });

        return { success: true };
      }

      // If not an admin, check regular users
      const { data: userProfile } = await supabase
        .from('users')
        .select('*')
        .eq('email', email)
        .single();

      if (!userProfile) {
        await LoginAttemptModel.create({
          email,
          ip_address: 'unknown',
          user_agent: navigator.userAgent,
          success: false
        });
        return { success: false, error: 'User not found' };
      }

      // Verify regular user password
      if (userProfile.password_hash !== btoa(password)) {
        await LoginAttemptModel.create({
          email,
          ip_address: 'unknown',
          user_agent: navigator.userAgent,
          success: false
        });
        return { success: false, error: 'Invalid password' };
      }

      // Create regular user data
      const userData: User = {
        id: userProfile.id,
        email: userProfile.email,
        full_name: userProfile.full_name,
        isEmailVerified: userProfile.is_email_verified,
        isPhoneVerified: userProfile.is_phone_verified,
        created_at: userProfile.created_at
      };

      // Generate token and set user
      const token = generateToken(userData);
      setUser(userData);
      localStorage.setItem('auth_user', JSON.stringify(userData));
      localStorage.setItem('auth_token', token);

      // Log successful attempt
      await LoginAttemptModel.create({
        email,
        ip_address: 'unknown',
        user_agent: navigator.userAgent,
        success: true
      });

      return { success: true };
    } catch (error) {
      errorLogger.log(error as Error, 'Login Error');
      
      // Log failed attempt
      try {
        await LoginAttemptModel.create({
          email,
          ip_address: 'unknown',
          user_agent: navigator.userAgent,
          success: false
        });
      } catch (logError) {
        errorLogger.log(logError as Error, 'Login Attempt Logging Error');
      }

      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'An error occurred during login'
      };
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('auth_user');
    localStorage.removeItem('auth_token');
  };

  const resendVerification = async (email: string): Promise<boolean> => {
    try {
      const verificationCode = Math.random().toString(36).substring(2, 8);
      
      // In a real app, you'd send this code via email
      console.log('Verification code:', verificationCode);
      
      // Store the verification code in the database
      const { error } = await supabase
        .from('verification_codes')
        .insert([{
          email,
          code: verificationCode,
          type: 'email',
          expires_at: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
        }]);

      if (error) throw error;
      return true;
    } catch (error) {
      errorLogger.log(error as Error, 'Resend Verification Error');
      return false;
    }
  };

  const verifyEmail = async (email: string, code: string): Promise<boolean> => {
    try {
      // Check if verification code is valid
      const { data: verificationData, error: verificationError } = await supabase
        .from('verification_codes')
        .select('*')
        .eq('email', email)
        .eq('code', code)
        .eq('type', 'email')
        .gt('expires_at', new Date().toISOString())
        .single();

      if (verificationError || !verificationData) {
        return false;
      }

      // Update user's email verification status
      const { error: updateError } = await supabase
        .from('users')
        .update({ is_email_verified: true })
        .eq('email', email);

      if (updateError) throw updateError;

      // Delete used verification code
      await supabase
        .from('verification_codes')
        .delete()
        .eq('email', email)
        .eq('code', code);

      return true;
    } catch (error) {
      errorLogger.log(error as Error, 'Verify Email Error');
      return false;
    }
  };

  const generateToken = (user: User): string => {
    const payload = {
      id: user.id,
      email: user.email,
      full_name: user.full_name,
      role: user.role,
      exp: Math.floor(Date.now() / 1000) + (24 * 60 * 60)
    };
    return btoa(JSON.stringify(payload));
  };

  const value = {
    user,
    login,
    logout,
    resendVerification,
    verifyEmail
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}