import React from 'react';

interface ProgressBarProps {
  label: string;
  value: number;
  total: number;
  color?: string;
}

export default function ProgressBar({ label, value, total, color = 'purple' }: ProgressBarProps) {
  const percentage = (value / total) * 100;

  return (
    <div className="relative">
      <div className="flex justify-between text-sm mb-1">
        <span className="text-gray-600">{label}</span>
        <span className="text-gray-900 font-medium">{percentage.toFixed(1)}%</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2">
        <div
          className={`h-2 bg-${color}-500 rounded-full`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
}