import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProcessAnimation from '../components/ProcessAnimation';
import MainDebtForm from '../components/MainDebtForm';
import PublicDebtCarousel from '../components/PublicDebtCarousel';

export default function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-pink-600">
      <Header />
      <main className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-bold text-white mb-4">
            Turn Awkward Money Talk into Fun! 💰
          </h1>
          <p className="text-xl text-white/90">
            The friendly way to remind someone they owe you something.
          </p>
        </div>

        <ProcessAnimation />
        <MainDebtForm />

        <div className="mt-16">
          <h2 className="text-3xl font-bold text-white text-center mb-8">
            Recent Public Debts 👀
          </h2>
          <PublicDebtCarousel />
        </div>
      </main>
      <Footer />
    </div>
  );
}