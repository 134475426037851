import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';

export interface User {
  id: string;
  email: string;
  password_hash: string;
  full_name: string;
  phone_number: string | null;
  country_of_residence: string;
  nationality: string;
  birthdate: string;
  is_phone_verified: boolean;
  is_email_verified: boolean;
  created_at: string;
  updated_at: string;
  status: 'active' | 'banned';
}

export interface UserCreate extends Omit<User, 'id' | 'is_phone_verified' | 'is_email_verified' | 'created_at' | 'updated_at' | 'status'> {}

export class UserModel {
  static async create(userData: UserCreate): Promise<User> {
    try {
      const { data: existingUser } = await supabase
        .from('users')
        .select('*')
        .eq('email', userData.email)
        .single();

      if (existingUser) {
        throw new Error('Email already registered');
      }

      const { data, error } = await supabase
        .from('users')
        .insert([userData])
        .select()
        .single();

      if (error) throw error;
      if (!data) throw new Error('Failed to create user');

      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'User Create Error');
      throw error;
    }
  }

  static async findById(id: string): Promise<User | null> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'Find User By ID Error');
      throw error;
    }
  }

  static async findByEmail(email: string): Promise<User | null> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('email', email)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'Find User By Email Error');
      throw error;
    }
  }

  static async findAll(): Promise<User[]> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'Find All Users Error');
      throw error;
    }
  }

  static async findTeamMembers(): Promise<User[]> {
    try {
      const { data, error } = await supabase
        .from('team_members')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'Find Team Members Error');
      throw error;
    }
  }

  static async update(id: string, data: Partial<User>): Promise<User> {
    try {
      const { data: updatedUser, error } = await supabase
        .from('users')
        .update({ ...data, updated_at: new Date().toISOString() })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      if (!updatedUser) throw new Error('Failed to update user');

      return updatedUser;
    } catch (error) {
      errorLogger.log(error as Error, 'User Update Error');
      throw error;
    }
  }

  static async updatePhoneVerification(userId: string, verified: boolean): Promise<void> {
    try {
      const { error } = await supabase
        .from('users')
        .update({
          is_phone_verified: verified,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'Update Phone Verification Error');
      throw error;
    }
  }

  static async updateEmailVerification(userId: string, verified: boolean): Promise<void> {
    try {
      const { error } = await supabase
        .from('users')
        .update({
          is_email_verified: verified,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'Update Email Verification Error');
      throw error;
    }
  }
}

export default UserModel;