import emailjs from '@emailjs/browser';
import { emailConfig } from '../config/email';

interface TeamInviteParams {
  name: string;
  role: string;
  token: string;
  tempPassword: string;
}

interface ClaimRemovedParams {
  category: string;
  subcategory: string;
  amount: number;
  description: string;
  reason: string;
}

export class EmailService {
  async sendVerificationEmail(to: string, verificationCode: any): Promise<void> {
    try {
      const verificationLink = `${window.location.origin}/verify/${verificationCode.code}`;
      
      await emailjs.send(
        emailConfig.serviceId,
        emailConfig.templates.verification,
        {
          to_email: to,
          verification_code: verificationCode.code,
          verification_link: verificationLink,
          email: to,
          code: verificationCode.code,
          link: verificationLink
        },
        emailConfig.publicKey
      );
    } catch (error) {
      console.error('Failed to send verification email:', error);
      throw error;
    }
  }

  async sendTeamInvitation(to: string, params: TeamInviteParams): Promise<void> {
    try {
      const inviteLink = `${window.location.origin}/team/verify/${params.token}`;
      
      await emailjs.send(
        emailConfig.serviceId,
        emailConfig.templates.teamInvite,
        {
          to_email: to,
          name: params.name,
          role: params.role,
          temp_password: params.tempPassword,
          invite_link: inviteLink,
          code: params.token,
          link: inviteLink
        },
        emailConfig.publicKey
      );
    } catch (error) {
      console.error('Failed to send team invitation:', error);
      throw error;
    }
  }

  async sendPasswordResetEmail(to: string, resetCode: string): Promise<void> {
    try {
      const resetLink = `${window.location.origin}/reset-password/${resetCode}`;

      await emailjs.send(
        emailConfig.serviceId,
        emailConfig.templates.passwordReset,
        {
          to_email: to,
          reset_code: resetCode,
          reset_link: resetLink,
          email: to,
          code: resetCode,
          link: resetLink
        },
        emailConfig.publicKey
      );
    } catch (error) {
      console.error('Failed to send password reset email:', error);
      throw error;
    }
  }

  async sendClaimRemovedEmail(to: string, params: ClaimRemovedParams): Promise<void> {
    try {
      await emailjs.send(
        emailConfig.serviceId,
        emailConfig.templates.claimRemoved,
        {
          to_email: to,
          category: `${params.category} - ${params.subcategory}`,
          amount: params.amount.toFixed(2),
          description: params.description,
          removal_reason: params.reason
        },
        emailConfig.publicKey
      );
    } catch (error) {
      console.error('Failed to send claim removed email:', error);
      throw error;
    }
  }
}

export const emailService = new EmailService();