import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrashIcon, CheckCircleIcon, ShareIcon, InformationCircleIcon, QrCodeIcon } from '@heroicons/react/24/outline';
import ShareDebtModal from './ShareDebtModal';
import QRCodeModal from '../QRCodeModal';
import { useAuth } from '../../contexts/AuthContext';
import { DebtRequestModel, type DebtRequest } from '../../db/models/DebtRequest';
import { errorLogger } from '../../utils/errorLogger';

interface DebtForm {
  category: string;
  subcategory: string;
  description: string;
  visibility: 'private' | 'public';
  amount: number;
  debtor: {
    email?: string;
    phone?: string;
    fullName?: string;
    social: {
      facebook?: string;
      instagram?: string;
      twitter?: string;
      tiktok?: string;
    };
  };
}

export default function DebtList() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [debts, setDebts] = useState<DebtRequest[]>([]);
  const [selectedDebt, setSelectedDebt] = useState<DebtRequest | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showIdTooltip, setShowIdTooltip] = useState<string | null>(null);
  const [stats, setStats] = useState({
    total: 0,
    settled: 0,
    pending: 0,
    successRate: 0
  });

  useEffect(() => {
    if (user) {
      loadDebts();
    }
  }, [user]);

  const loadDebts = async () => {
    if (!user) return;
    
    try {
      setIsLoading(true);
      const [userDebts, statistics] = await Promise.all([
        DebtRequestModel.findByCreditor(user.id),
        DebtRequestModel.getStatistics(user.id)
      ]);
      setDebts(userDebts);
      setStats(statistics);
    } catch (error) {
      console.error('Error loading debts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSettle = async (id: string) => {
    try {
      await DebtRequestModel.updateStatus(id, 'settled');
      await loadDebts();
    } catch (error) {
      console.error('Error settling debt:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await DebtRequestModel.delete(id);
      await loadDebts();
    } catch (error) {
      console.error('Error deleting debt:', error);
    }
  };

  const handleShare = (debt: DebtRequest) => {
    setSelectedDebt(debt);
    setIsShareModalOpen(true);
  };

  const handleShowQRCode = (debt: DebtRequest) => {
    setSelectedDebt(debt);
    setIsQRCodeModalOpen(true);
  };

  const toggleIdTooltip = (id: string | null) => {
    setShowIdTooltip(id);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-gradient-to-br from-purple-500 to-pink-500 rounded-xl p-6 text-white shadow-lg">
          <h3 className="text-lg font-semibold mb-2">Outstanding Debts</h3>
          <div className="flex items-baseline">
            <p className="text-3xl font-bold">{stats.pending}</p>
            <p className="text-lg ml-2">debts</p>
          </div>
          <p className="text-sm opacity-75 mt-2">People who owe you something!</p>
          <div className="mt-4 text-xs">
            <span className="inline-flex items-center px-2 py-1 bg-white/20 rounded-full">
              🤝 Friendly reminders sent
            </span>
          </div>
        </div>

        <div className="bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl p-6 text-white shadow-lg">
          <h3 className="text-lg font-semibold mb-2">Debts Settled</h3>
          <div className="flex items-baseline">
            <p className="text-3xl font-bold">{stats.settled}</p>
            <p className="text-lg ml-2">total</p>
          </div>
          <p className="text-sm opacity-75 mt-2">Successfully settled debts!</p>
          <div className="mt-4 text-xs">
            <span className="inline-flex items-center px-2 py-1 bg-white/20 rounded-full">
              🎉 Friendships preserved
            </span>
          </div>
        </div>

        <div className="bg-gradient-to-br from-green-500 to-teal-500 rounded-xl p-6 text-white shadow-lg">
          <h3 className="text-lg font-semibold mb-2">Success Rate</h3>
          <div className="flex items-baseline">
            <p className="text-3xl font-bold">{stats.successRate}%</p>
          </div>
          <p className="text-sm opacity-75 mt-2">Of debts get settled!</p>
          <div className="mt-4 text-xs">
            <span className="inline-flex items-center px-2 py-1 bg-white/20 rounded-full">
              ⭐️ Above average performance
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">
            Active Debt Requests
          </h3>
        </div>

        {debts.length === 0 ? (
          <div className="p-6 text-center text-gray-500">
            <p>No debt requests yet. Create your first one!</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Debtor
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Since When
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {debts.map((debt) => (
                  <tr key={debt.id} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="relative">
                        <button
                          onClick={() => toggleIdTooltip(showIdTooltip === debt.id ? null : debt.id)}
                          className="text-gray-400 hover:text-gray-600 transition-colors"
                        >
                          <InformationCircleIcon className="h-5 w-5" />
                        </button>
                        {showIdTooltip === debt.id && (
                          <div className="absolute z-10 mt-2 px-2 py-1 text-xs bg-gray-900 text-white rounded shadow-lg whitespace-nowrap">
                            {debt.id}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {debt.debtor_name || 'Unknown'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {debt.category} - {debt.subcategory}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${debt.amount.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {debt.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(debt.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        debt.status === 'settled' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {debt.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex space-x-2">
                        {debt.status === 'pending' && (
                          <>
                            <button
                              onClick={() => handleShare(debt)}
                              className="text-blue-600 hover:text-blue-900 transition-colors"
                              title="Share"
                            >
                              <ShareIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleShowQRCode(debt)}
                              className="text-purple-600 hover:text-purple-900 transition-colors"
                              title="QR Code"
                            >
                              <QrCodeIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleSettle(debt.id)}
                              className="text-green-600 hover:text-green-900 transition-colors"
                              title="Mark as Settled"
                            >
                              <CheckCircleIcon className="h-5 w-5" />
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => handleDelete(debt.id)}
                          className="text-red-600 hover:text-red-900 transition-colors"
                          title="Delete"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {selectedDebt && (
        <>
          <ShareDebtModal
            isOpen={isShareModalOpen}
            onClose={() => setIsShareModalOpen(false)}
            debt={selectedDebt}
          />
          <QRCodeModal
            isOpen={isQRCodeModalOpen}
            onClose={() => setIsQRCodeModalOpen(false)}
            debtUrl={`${window.location.origin}/d/${selectedDebt.public_token}`}
          />
        </>
      )}
    </div>
  );
}