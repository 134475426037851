import { supabase } from '../config/database';
import { errorLogger } from '../utils/errorLogger';

export async function initDatabase(): Promise<void> {
  try {
    // Test connection by checking if users table exists
    const { error } = await supabase
      .from('users')
      .select('id')
      .limit(1);

    if (error) throw error;
    console.log('Database connection successful');
  } catch (error) {
    errorLogger.log(error as Error, 'Database Initialization Error');
    console.error('Failed to initialize database:', error);
    throw error;
  }
}

export default supabase;