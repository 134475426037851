import React, { useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import DebtList from '../components/dashboard/DebtList';
import NewDebtRequest from '../components/dashboard/NewDebtRequest';
import Settings from '../components/dashboard/Settings';
import Statistics from '../components/dashboard/Statistics';
import Profile from '../components/dashboard/Profile';
import History from '../components/dashboard/History';

export type DashboardView = 'overview' | 'new-request' | 'settings' | 'statistics' | 'profile' | 'history';

const viewToPath: Record<DashboardView, string> = {
  'overview': '',
  'new-request': 'new',
  'settings': 'settings',
  'statistics': 'statistics',
  'profile': 'profile',
  'history': 'history'
};

const pathToView: Record<string, DashboardView> = {
  '': 'overview',
  'new': 'new-request',
  'settings': 'settings',
  'statistics': 'statistics',
  'profile': 'profile',
  'history': 'history'
};

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop() || '';
  const [currentView, setCurrentView] = useState<DashboardView>(pathToView[currentPath] || 'overview');

  const handleViewChange = (view: DashboardView) => {
    setCurrentView(view);
    navigate(`/dashboard/${viewToPath[view]}`);
  };

  return (
    <DashboardLayout currentView={currentView} onViewChange={handleViewChange}>
      <Routes>
        <Route path="/" element={<DebtList />} />
        <Route path="new" element={<NewDebtRequest />} />
        <Route path="settings" element={<Settings />} />
        <Route path="statistics" element={<Statistics />} />
        <Route path="profile" element={<Profile />} />
        <Route path="history" element={<History />} />
      </Routes>
    </DashboardLayout>
  );
}