import React from 'react';
import type { LoginAttempt } from '../../db/models/LoginAttempt';

interface LoginAttemptsTableProps {
  attempts: LoginAttempt[];
}

export default function LoginAttemptsTable({ attempts }: LoginAttemptsTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">IP Address</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {attempts.map((attempt) => (
            <tr key={attempt.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{attempt.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{attempt.ip_address}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {attempt.location?.city}, {attempt.location?.country}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  attempt.success ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {attempt.success ? 'Success' : 'Failed'}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(attempt.created_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}