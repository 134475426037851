import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface PrivacyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyModal({ isOpen, onClose }: PrivacyModalProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl rounded-2xl bg-gradient-to-br from-white to-purple-50 p-8 max-h-[80vh] overflow-y-auto shadow-2xl relative">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
          
          <Dialog.Title className="text-3xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
            Privacy Policy
          </Dialog.Title>
          
          <div className="prose max-w-none">
            <div className="space-y-8">
              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">1. Website Responsibility</h2>
                <p className="text-gray-600">www.repaye.com ("we," "us," or "our") operates this website as an entertainment platform only. We make no guarantees regarding:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    The accuracy or completeness of any information
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    The security of your personal information beyond reasonable measures
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    The availability or uninterrupted access to the service
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">2. Information Collection Disclaimer</h2>
                <p className="text-gray-600">While we collect minimal information necessary for the platform's operation, we:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Cannot guarantee absolute security of data transmission
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Are not responsible for data breaches beyond our control
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    May share information if required by law
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">3. Third-Party Services</h2>
                <p className="text-gray-600">We use Facebook for social features. We:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Are not responsible for Facebook's privacy practices
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Cannot control how Facebook uses your data
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Recommend reviewing Facebook's privacy policy
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">4. Data Protection Limitations</h2>
                <p className="text-gray-600">While we implement reasonable security measures, we cannot guarantee:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Complete security of your personal information
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Protection against all unauthorized access
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Prevention of all potential security breaches
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">5. Changes to Privacy Policy</h2>
                <p className="text-gray-600">We reserve the right to modify this privacy policy at any time. Changes will be effective immediately upon posting to the website. Your continued use of the service constitutes acceptance of such changes.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">6. Contact</h2>
                <p className="text-gray-600">For privacy concerns, contact us at privacy@repaye.com. However, please note that response times are not guaranteed, and we may be limited in our ability to address certain requests.</p>
              </section>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}