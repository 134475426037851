import React from 'react';
import ProgressBar from './ProgressBar';

interface AnalyticsSectionProps {
  title: string;
  data: { [key: string]: number };
  total: number;
  color?: string;
}

export default function AnalyticsSection({ title, data, total, color }: AnalyticsSectionProps) {
  return (
    <div>
      <h3 className="text-sm font-medium text-gray-500 mb-2">{title}</h3>
      <div className="space-y-2">
        {Object.entries(data).map(([key, value]) => (
          <ProgressBar
            key={key}
            label={key}
            value={value}
            total={total}
            color={color}
          />
        ))}
      </div>
    </div>
  );
}