import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function TermsModal({ isOpen, onClose }: TermsModalProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl rounded-2xl bg-gradient-to-br from-white to-purple-50 p-8 max-h-[80vh] overflow-y-auto shadow-2xl relative">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
          
          <Dialog.Title className="text-3xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
            Terms and Agreements
          </Dialog.Title>
          
          <div className="prose max-w-none">
            <div className="space-y-8">
              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">1. Website Disclaimer</h2>
                <p className="text-gray-600">www.repaye.com ("the Website") is provided "as is" and "as available" without any representations or warranties, express or implied. The Website makes no representations or warranties relating to this website or the information and materials provided on this website.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">2. Limitation of Liability</h2>
                <p className="text-gray-600">In no event shall www.repaye.com, its operators, owners, or affiliates be liable for:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Any actual debts or financial obligations between users
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Any disputes arising between users
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Any direct, indirect, special, or consequential damages
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Any loss of data, profit, revenue, or business interruption
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">3. Entertainment Purpose Only</h2>
                <p className="text-gray-600">This platform is designed for entertainment purposes only. Any "debt claims" made through this service are not legally binding and should be treated as playful reminders between friends and acquaintances.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">4. User Conduct</h2>
                <p className="text-gray-600">Users agree to:</p>
                <ul className="list-none space-y-2 mt-3">
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Use the platform in good humor and fun
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Not harass or intimidate other users
                  </li>
                  <li className="flex items-center text-gray-600">
                    <span className="h-2 w-2 rounded-full bg-purple-400 mr-3"></span>
                    Not use the platform for actual debt collection
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">5. Indemnification</h2>
                <p className="text-gray-600">Users agree to indemnify and hold harmless www.repaye.com, its operators, owners, and affiliates from and against any claims, damages, obligations, losses, liabilities, costs, or debt.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 text-purple-700">6. Governing Law</h2>
                <p className="text-gray-600">These terms shall be governed by and construed in accordance with the laws of Thailand, and any disputes will be subject to the exclusive jurisdiction of the Thai courts.</p>
              </section>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}