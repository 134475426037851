import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupForm from '../components/SignupForm';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function SignupPage() {
  const navigate = useNavigate();
  const [verificationSent, setVerificationSent] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const handleSignupComplete = (email: string) => {
    setUserEmail(email);
    setVerificationSent(true);
    // In a real app, redirect to verification pending page
    setTimeout(() => {
      navigate('/dashboard');
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-pink-600">
      <Header />
      <main className="container mx-auto px-4 py-12">
        {verificationSent ? (
          <div className="max-w-md mx-auto bg-white rounded-lg shadow-xl p-8 text-center">
            <div className="text-6xl mb-4">✉️</div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Verification Email Sent!
            </h2>
            <p className="text-gray-600 mb-6">
              We've sent a verification link to <strong>{userEmail}</strong>. 
              Please check your email and click the link to verify your account.
            </p>
            <div className="animate-pulse text-sm text-gray-500">
              Redirecting to dashboard in a moment...
            </div>
          </div>
        ) : (
          <SignupForm onSignupComplete={handleSignupComplete} />
        )}
      </main>
      <Footer />
    </div>
  );
}