import { supabase } from '../../config/database';
import { emailService } from '../../services/EmailService';
import { errorLogger } from '../../utils/errorLogger';
import { v4 as uuidv4 } from 'uuid';

export interface TeamMember {
  id: string;
  email: string;
  full_name: string;
  role: 'admin' | 'moderator' | 'support';
  permissions: string[];
  status: 'active' | 'inactive' | 'pending';
  verification_token?: string;
  email_verified: boolean;
  created_at: string;
  last_login?: string;
}

export interface TeamMemberCreate {
  email: string;
  full_name: string;
  role: TeamMember['role'];
  password: string;
}

const DEFAULT_PERMISSIONS: Record<TeamMember['role'], string[]> = {
  admin: ['all'],
  moderator: ['manage_debts', 'manage_users', 'view_stats'],
  support: ['view_debts', 'view_users']
};

export class TeamMemberModel {
  static async getAll(): Promise<TeamMember[]> {
    try {
      const { data, error } = await supabase
        .from('team_members')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'Team Members Get All Error');
      throw error;
    }
  }

  static async getActiveAdminCount(): Promise<number> {
    try {
      const { data, error } = await supabase
        .from('team_members')
        .select('id')
        .eq('role', 'admin')
        .eq('status', 'active');

      if (error) throw error;
      return data?.length || 0;
    } catch (error) {
      errorLogger.log(error as Error, 'Get Active Admin Count Error');
      throw error;
    }
  }

  static async create(member: TeamMemberCreate): Promise<TeamMember> {
    try {
      // Check if email already exists
      const { data: existingMember } = await supabase
        .from('team_members')
        .select('*')
        .eq('email', member.email)
        .single();

      if (existingMember) {
        throw new Error('Email already registered');
      }

      // Generate verification token
      const verificationToken = uuidv4();

      // Get default permissions for role
      const permissions = DEFAULT_PERMISSIONS[member.role];

      const { data, error } = await supabase
        .from('team_members')
        .insert([{
          email: member.email,
          full_name: member.full_name,
          role: member.role,
          permissions,
          status: 'pending',
          verification_token: verificationToken,
          password_hash: btoa(member.password) // Simple hash for demo
        }])
        .select()
        .single();

      if (error) throw error;
      if (!data) throw new Error('Failed to create team member');

      // Send invitation email
      await emailService.sendTeamInvitation(member.email, {
        name: member.full_name,
        role: member.role,
        token: verificationToken,
        tempPassword: member.password
      });

      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'Team Member Create Error');
      throw error;
    }
  }

  static async resendInvitation(id: string): Promise<void> {
    try {
      // Get member details
      const { data: member, error: findError } = await supabase
        .from('team_members')
        .select('*')
        .eq('id', id)
        .single();

      if (findError || !member) throw new Error('Team member not found');

      // Generate new verification token
      const verificationToken = uuidv4();

      // Update member with new token
      const { error: updateError } = await supabase
        .from('team_members')
        .update({ verification_token: verificationToken })
        .eq('id', id);

      if (updateError) throw updateError;

      // Generate temporary password
      const tempPassword = Math.random().toString(36).slice(-8);

      // Update password
      const { error: passwordError } = await supabase
        .from('team_members')
        .update({ password_hash: btoa(tempPassword) })
        .eq('id', id);

      if (passwordError) throw passwordError;

      // Send new invitation email
      await emailService.sendTeamInvitation(member.email, {
        name: member.full_name,
        role: member.role,
        token: verificationToken,
        tempPassword: tempPassword
      });
    } catch (error) {
      errorLogger.log(error as Error, 'Team Member Resend Invitation Error');
      throw error;
    }
  }

  static async update(id: string, updates: Partial<TeamMember>): Promise<TeamMember> {
    try {
      // If updating role from admin to something else, check admin count
      if (updates.role && updates.role !== 'admin') {
        const { data: currentMember } = await supabase
          .from('team_members')
          .select('role')
          .eq('id', id)
          .single();

        if (currentMember?.role === 'admin') {
          const adminCount = await this.getActiveAdminCount();
          if (adminCount <= 1) {
            throw new Error('Cannot change role: This is the last admin account');
          }
        }
      }

      const { data, error } = await supabase
        .from('team_members')
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      if (!data) throw new Error('Failed to update team member');
      return data;
    } catch (error) {
      errorLogger.log(error as Error, 'Team Member Update Error');
      throw error;
    }
  }

  static async delete(id: string): Promise<void> {
    try {
      // Get member details first
      const { data: member, error: findError } = await supabase
        .from('team_members')
        .select('*')
        .eq('id', id)
        .single();

      if (findError) throw findError;
      if (!member) throw new Error('Team member not found');

      // If member is an admin, check if they're the last one
      if (member.role === 'admin' && member.status === 'active') {
        const adminCount = await this.getActiveAdminCount();
        if (adminCount <= 1) {
          throw new Error('Cannot delete the last admin account');
        }
      }

      const { error } = await supabase
        .from('team_members')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      errorLogger.log(error as Error, 'Team Member Delete Error');
      throw error;
    }
  }

  static async verifyAndActivate(verificationCode: string, memberId: string): Promise<void> {
    try {
      const { data: member, error: findError } = await supabase
        .from('team_members')
        .select('*')
        .eq('id', memberId)
        .single();

      if (findError || !member) {
        throw new Error('Team member not found');
      }

      if (member.verification_token !== verificationCode) {
        throw new Error('Invalid verification code');
      }

      const { error: updateError } = await supabase
        .from('team_members')
        .update({
          status: 'active',
          verification_token: null,
          email_verified: true
        })
        .eq('id', memberId);

      if (updateError) throw updateError;
    } catch (error) {
      errorLogger.log(error as Error, 'Team Member Manual Activation Error');
      throw error;
    }
  }
}