import React from 'react';
import { countries } from '../../data/countries';
import { FormData, ValidationErrors } from './types';

interface DetailedInfoSectionProps {
  formData: FormData;
  errors: ValidationErrors;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBack: () => void;
  onSubmit: (e: React.FormEvent) => void;
  isSubmitting: boolean;
}

export default function DetailedInfoSection({
  formData,
  errors,
  onChange,
  onBack,
  onSubmit,
  isSubmitting
}: DetailedInfoSectionProps) {
  return (
    <div className="space-y-6">
      <div className="group">
        <label className="block text-sm font-medium text-gray-700 group-hover:text-purple-600 transition-colors">
          Full Name
        </label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={onChange}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 transition-all duration-300 hover:border-purple-300"
          required
        />
        {errors.fullName && <p className="mt-1 text-sm text-red-600">{errors.fullName}</p>}
      </div>

      <div className="group">
        <label className="block text-sm font-medium text-gray-700 group-hover:text-purple-600 transition-colors">
          Phone Number
        </label>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={onChange}
          placeholder="+1234567890"
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 transition-all duration-300 hover:border-purple-300"
          required
        />
        {errors.phoneNumber && <p className="mt-1 text-sm text-red-600">{errors.phoneNumber}</p>}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="group">
          <label className="block text-sm font-medium text-gray-700 group-hover:text-purple-600 transition-colors">
            Country of Residence
          </label>
          <select
            name="countryOfResidence"
            value={formData.countryOfResidence}
            onChange={onChange}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 transition-all duration-300 hover:border-purple-300"
            required
          >
            <option value="">Select a country</option>
            {countries.map(country => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.countryOfResidence && <p className="mt-1 text-sm text-red-600">{errors.countryOfResidence}</p>}
        </div>

        <div className="group">
          <label className="block text-sm font-medium text-gray-700 group-hover:text-purple-600 transition-colors">
            Nationality
          </label>
          <select
            name="nationality"
            value={formData.nationality}
            onChange={onChange}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 transition-all duration-300 hover:border-purple-300"
            required
          >
            <option value="">Select nationality</option>
            {countries.map(country => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.nationality && <p className="mt-1 text-sm text-red-600">{errors.nationality}</p>}
        </div>
      </div>

      <div className="group">
        <label className="block text-sm font-medium text-gray-700 group-hover:text-purple-600 transition-colors">
          Date of Birth
        </label>
        <input
          type="date"
          name="birthdate"
          value={formData.birthdate}
          onChange={onChange}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 transition-all duration-300 hover:border-purple-300"
          required
        />
        {errors.birthdate && <p className="mt-1 text-sm text-red-600">{errors.birthdate}</p>}
      </div>

      <div className="flex space-x-4">
        <button
          type="button"
          onClick={onBack}
          className="flex-1 bg-gray-100 text-gray-700 font-bold py-3 px-4 rounded-lg hover:bg-gray-200 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          ← Back
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          onClick={onSubmit}
          className="flex-1 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-bold py-3 px-4 rounded-lg hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
        >
          {isSubmitting ? (
            <div className="flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Creating Account...
            </div>
          ) : (
            'Create Account'
          )}
        </button>
      </div>
    </div>
  );
}