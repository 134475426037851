import React, { useState, useEffect } from 'react';
import { 
  ChartBarIcon, 
  ClockIcon, 
  UserGroupIcon, 
  SparklesIcon 
} from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';
import { DebtRequestModel } from '../../db/models/DebtRequest';
import type { DebtRequest } from '../../db/models/DebtRequest';

interface CategoryStats {
  [key: string]: number;
}

interface TimelineStats {
  within1Week: number;
  within2Weeks: number;
  within1Month: number;
}

export default function Statistics() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [debts, setDebts] = useState<DebtRequest[]>([]);
  const [categoryStats, setCategoryStats] = useState<CategoryStats>({});
  const [timelineStats, setTimelineStats] = useState<TimelineStats>({
    within1Week: 0,
    within2Weeks: 0,
    within1Month: 0
  });
  const [stats, setStats] = useState({
    total: 0,
    settled: 0,
    pending: 0,
    successRate: 0
  });

  useEffect(() => {
    const loadStatistics = async () => {
      if (!user) return;
      
      try {
        setIsLoading(true);
        const [userDebts, statistics] = await Promise.all([
          DebtRequestModel.findByCreditor(user.id),
          DebtRequestModel.getStatistics(user.id)
        ]);
        
        setDebts(userDebts);
        setStats(statistics);

        // Calculate category statistics
        const catStats: CategoryStats = {};
        userDebts.forEach((debt: DebtRequest) => {
          catStats[debt.category] = (catStats[debt.category] || 0) + 1;
        });

        // Convert to percentages
        const total = userDebts.length;
        Object.keys(catStats).forEach(key => {
          catStats[key] = Math.round((catStats[key] / total) * 100);
        });
        setCategoryStats(catStats);

        // Calculate timeline statistics
        const now = new Date();
        const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
        const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        const settledDebts = userDebts.filter((debt: DebtRequest) => debt.status === 'settled');
        const timeStats = {
          within1Week: settledDebts.filter((debt: DebtRequest) => new Date(debt.updated_at) >= oneWeekAgo).length,
          within2Weeks: settledDebts.filter((debt: DebtRequest) => new Date(debt.updated_at) >= twoWeeksAgo).length,
          within1Month: settledDebts.filter((debt: DebtRequest) => new Date(debt.updated_at) >= oneMonthAgo).length
        };

        // Convert to percentages
        const totalSettled = settledDebts.length;
        if (totalSettled > 0) {
          timeStats.within1Week = Math.round((timeStats.within1Week / totalSettled) * 100);
          timeStats.within2Weeks = Math.round((timeStats.within2Weeks / totalSettled) * 100);
          timeStats.within1Month = Math.round((timeStats.within1Month / totalSettled) * 100);
        }

        setTimelineStats(timeStats);
      } catch (error) {
        console.error('Error loading statistics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadStatistics();
  }, [user]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-lg font-semibold mb-4">Settlement Timeline</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <div className="w-1/3 text-sm text-gray-600">Within 1 week</div>
              <div className="w-2/3">
                <div className="h-4 bg-gray-100 rounded-full">
                  <div 
                    className="h-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full" 
                    style={{ width: `${timelineStats.within1Week}%` }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/3 text-sm text-gray-600">Within 2 weeks</div>
              <div className="w-2/3">
                <div className="h-4 bg-gray-100 rounded-full">
                  <div 
                    className="h-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full" 
                    style={{ width: `${timelineStats.within2Weeks}%` }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/3 text-sm text-gray-600">Within 1 month</div>
              <div className="w-2/3">
                <div className="h-4 bg-gray-100 rounded-full">
                  <div 
                    className="h-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full" 
                    style={{ width: `${timelineStats.within1Month}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-lg font-semibold mb-4">Most Common Categories</h3>
          <div className="space-y-4">
            {Object.entries(categoryStats)
              .sort(([, a], [, b]) => b - a)
              .map(([category, percentage]) => (
                <div key={category} className="flex items-center justify-between">
                  <span className="text-gray-600">
                    {category}
                  </span>
                  <span className="font-semibold">{percentage}%</span>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-gradient-to-br from-purple-500 to-pink-500 rounded-xl p-6 text-white">
          <ChartBarIcon className="h-8 w-8 mb-4" />
          <div className="text-2xl font-bold">{stats.successRate}%</div>
          <div className="text-sm opacity-75">Success Rate</div>
        </div>

        <div className="bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl p-6 text-white">
          <ClockIcon className="h-8 w-8 mb-4" />
          <div className="text-2xl font-bold">
            {timelineStats.within1Week > 0 ? '5 Days' : 'N/A'}
          </div>
          <div className="text-sm opacity-75">Average Settlement Time</div>
        </div>

        <div className="bg-gradient-to-br from-green-500 to-teal-500 rounded-xl p-6 text-white">
          <UserGroupIcon className="h-8 w-8 mb-4" />
          <div className="text-2xl font-bold">{stats.total}</div>
          <div className="text-sm opacity-75">Total Requests</div>
        </div>

        <div className="bg-gradient-to-br from-yellow-500 to-orange-500 rounded-xl p-6 text-white">
          <SparklesIcon className="h-8 w-8 mb-4" />
          <div className="text-2xl font-bold">
            {stats.settled}/{stats.total}
          </div>
          <div className="text-sm opacity-75">Settled/Total</div>
        </div>
      </div>
    </div>
  );
}