import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DebtRequestModel, type DebtRequest } from '../db/models/DebtRequest';
import PublicDebtCarousel from '../components/PublicDebtCarousel';
import { errorLogger } from '../utils/errorLogger';

export default function PublicDebtView() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [debt, setDebt] = useState<DebtRequest | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadDebt = async () => {
      if (!token) {
        navigate('/404');
        return;
      }

      try {
        const debtRequest = await DebtRequestModel.findByPublicToken(token);
        if (!debtRequest) {
          navigate('/404');
        } else {
          setDebt(debtRequest);
        }
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load debt request';
        setError(errorMessage);
        errorLogger.log(err as Error, 'Public Debt View Load Error');
      } finally {
        setIsLoading(false);
      }
    };

    loadDebt();
  }, [token, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div>
      </div>
    );
  }

  if (error || !debt) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full text-center">
          <div className="text-6xl mb-4">😕</div>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Oops!</h1>
          <p className="text-gray-600">{error || 'Something went wrong'}</p>
          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Check out other public debts
            </h2>
            <PublicDebtCarousel />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-2xl w-full">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Debt Request</h1>
          <p className="text-gray-600">ID: {debt.public_token}</p>
        </div>

        <div className="space-y-6">
          <div className="border-b border-gray-200 pb-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Status</h2>
                <p className={`mt-1 text-lg font-semibold ${
                  debt.status === 'settled' 
                    ? 'text-green-600' 
                    : debt.status === 'cancelled'
                    ? 'text-red-600'
                    : 'text-yellow-600'
                }`}>
                  {debt.status.charAt(0).toUpperCase() + debt.status.slice(1)}
                </p>
              </div>
              <div>
                <h2 className="text-sm font-medium text-gray-500">Created On</h2>
                <p className="mt-1 text-lg">
                  {new Date(debt.created_at).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <h2 className="text-sm font-medium text-gray-500">Debtor</h2>
              <p className="mt-1 text-lg font-semibold text-gray-800">
                {debt.debtor_name || 'Anonymous'}
              </p>
            </div>

            {debt.amount > 0 && (
              <div>
                <h2 className="text-sm font-medium text-gray-500">Amount</h2>
                <p className="mt-1 text-2xl font-bold text-purple-600">
                  ${debt.amount.toFixed(2)}
                </p>
              </div>
            )}

            <div>
              <h2 className="text-sm font-medium text-gray-500">Category</h2>
              <p className="mt-1 text-lg text-gray-800">
                {debt.category} - {debt.subcategory}
              </p>
            </div>

            <div>
              <h2 className="text-sm font-medium text-gray-500">Description</h2>
              <p className="mt-1 text-lg text-gray-800">
                {debt.description}
              </p>
            </div>
          </div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <a
              href="/"
              className="block w-full text-center bg-purple-600 text-white py-3 px-4 rounded-lg hover:bg-purple-700 transition-colors"
            >
              Visit Repaye
            </a>
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Other Public Debts
          </h2>
          <PublicDebtCarousel />
        </div>
      </div>
    </div>
  );
}