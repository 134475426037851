import React from 'react';
import { 
  HomeIcon, 
  UserCircleIcon, 
  CogIcon, 
  PlusCircleIcon,
  ClockIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';
import { DashboardView } from '../../pages/Dashboard';

interface SidebarProps {
  currentView: DashboardView;
  onViewChange: (view: DashboardView) => void;
}

interface NavItem {
  name: string;
  view: DashboardView;
  icon: React.ComponentType<any>;
  color: string;
}

export default function Sidebar({ currentView, onViewChange }: SidebarProps) {
  const navigation: NavItem[] = [
    { 
      name: 'Dashboard', 
      view: 'overview',
      icon: HomeIcon, 
      color: 'from-purple-600 to-pink-600'
    },
    { 
      name: 'New Request', 
      view: 'new-request',
      icon: PlusCircleIcon, 
      color: 'from-green-600 to-teal-600'
    },
    { 
      name: 'History', 
      view: 'history',
      icon: ClockIcon, 
      color: 'from-blue-600 to-indigo-600'
    },
    { 
      name: 'Statistics', 
      view: 'statistics',
      icon: ChartBarIcon, 
      color: 'from-orange-600 to-red-600'
    },
    { 
      name: 'Profile', 
      view: 'profile',
      icon: UserCircleIcon, 
      color: 'from-yellow-600 to-amber-600'
    },
    { 
      name: 'Settings', 
      view: 'settings',
      icon: CogIcon, 
      color: 'from-purple-600 to-indigo-600'
    }
  ];

  return (
    <div className="w-64 bg-white shadow-xl h-[calc(100vh-4rem)] overflow-y-auto">
      <nav className="mt-5 px-2">
        <div className="space-y-1">
          {navigation.map((item) => (
            <button
              key={item.name}
              onClick={() => onViewChange(item.view)}
              className={`group flex items-center px-3 py-3 text-base font-medium rounded-lg w-full text-left transition-all duration-200
                ${currentView === item.view
                  ? `bg-gradient-to-r ${item.color} text-white`
                  : 'text-gray-600 hover:bg-gradient-to-r hover:' + item.color + ' hover:text-white'
                }`}
            >
              <item.icon
                className={`mr-4 h-6 w-6 ${
                  currentView === item.view
                    ? 'text-white'
                    : 'text-gray-400 group-hover:text-white'
                } transition-colors duration-200`}
                aria-hidden="true"
              />
              {item.name}
            </button>
          ))}
        </div>
      </nav>

      <div className="px-4 mt-8">
        <div className="bg-gradient-to-br from-purple-500 to-pink-500 rounded-lg p-4 text-white">
          <h3 className="font-bold mb-2">Need Help?</h3>
          <p className="text-sm opacity-90 mb-3">Check our documentation or contact support</p>
          <button className="bg-white text-purple-600 px-4 py-2 rounded-lg text-sm font-semibold hover:bg-purple-50 transition-colors w-full">
            Get Support
          </button>
        </div>
      </div>
    </div>
  );
}