import React from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';

export default function Header() {
  const { user, logout } = useAuth();

  // Get first letter of name safely
  const getInitial = () => {
    if (!user?.full_name) return '?';
    return user.full_name.charAt(0);
  };

  return (
    <header className="bg-gradient-to-r from-purple-600 to-pink-600 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0 flex items-center space-x-2">
              <div className="w-10 h-10 relative">
                <svg viewBox="0 0 100 100" className="w-full h-full">
                  <circle cx="50" cy="50" r="45" fill="white" stroke="black" strokeWidth="5"/>
                  <path d="M50,20 A30,30 0 0,1 80,50" fill="none" stroke="black" strokeWidth="5"/>
                  <path d="M50,80 A30,30 0 0,1 20,50" fill="none" stroke="black" strokeWidth="5"/>
                  <circle cx="35" cy="40" r="5" fill="black"/>
                  <circle cx="65" cy="40" r="5" fill="black"/>
                  <path d="M35,60 Q50,70 65,60" fill="none" stroke="black" strokeWidth="5"/>
                  <path d="M20,50 L30,45 L30,55 Z" fill="black"/>
                  <path d="M80,50 L70,45 L70,55 Z" fill="black"/>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-white">REPAYE</h1>
                <p className="text-xs text-white/80">
                  {user ? `Welcome, ${user.full_name}` : 'Welcome'}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button className="p-2 rounded-full hover:bg-white/10 relative">
              <BellIcon className="h-6 w-6 text-white" />
              <span className="absolute top-1 right-1 h-3 w-3 bg-red-500 rounded-full animate-pulse"></span>
            </button>
            <div className="flex items-center space-x-3">
              <div className="h-8 w-8 rounded-full bg-white/20 flex items-center justify-center text-white font-bold">
                {getInitial()}
              </div>
              <button
                onClick={logout}
                className="text-white hover:text-white/80 font-medium transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}