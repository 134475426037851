import { useState, useEffect } from 'react';

export function useFacebookSDK() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Only set up FB SDK once
    if (window.FB) {
      setIsInitialized(true);
      return;
    }

    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '1234567890', // Replace with your actual Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v18.0'
      });
      setIsInitialized(true);
    };

    // Load the SDK asynchronously
    const loadSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    };

    loadSDK();

    // Cleanup
    return () => {
      if (window.fbAsyncInit) {
        window.fbAsyncInit = undefined;
      }
    };
  }, []);

  return isInitialized;
}