import { supabase } from '../../config/database';
import { errorLogger } from '../../utils/errorLogger';

export interface VisitorAnalytics {
  id: string;
  ip_address: string;
  user_agent: string;
  referrer?: string;
  referrer_type: 'direct' | 'search' | 'social' | 'other';
  path: string;
  location?: {
    country?: string;
    city?: string;
    region?: string;
  };
  device_info?: {
    browser?: string;
    os?: string;
    device?: string;
    language?: string;
    screen_resolution?: string;
    timezone?: string;
  };
  created_at: string;
}

export class VisitorAnalyticsModel {
  static async create(data: Omit<VisitorAnalytics, 'id' | 'created_at'>): Promise<VisitorAnalytics> {
    try {
      const { data: visit, error } = await supabase
        .from('visitor_analytics')
        .insert([data])
        .select()
        .single();

      if (error) throw error;
      if (!visit) throw new Error('Failed to create visitor analytics record');

      return visit;
    } catch (error) {
      errorLogger.log(error as Error, 'VisitorAnalytics Create Error');
      throw error;
    }
  }

  static async getAll(limit: number = 100): Promise<VisitorAnalytics[]> {
    try {
      const { data, error } = await supabase
        .from('visitor_analytics')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) throw error;
      return data || [];
    } catch (error) {
      errorLogger.log(error as Error, 'VisitorAnalytics GetAll Error');
      throw error;
    }
  }

  static async getStats(): Promise<{
    totalVisits: number;
    uniqueVisitors: number;
    countries: { [key: string]: number };
    browsers: { [key: string]: number };
    os: { [key: string]: number };
    trafficSources: { [key: string]: number };
    topReferrers: { [key: string]: number };
  }> {
    try {
      const { data, error } = await supabase
        .from('visitor_analytics')
        .select('*');

      if (error) throw error;

      const visits = data || [];
      const uniqueIPs = new Set(visits.map(v => v.ip_address));
      const countries: { [key: string]: number } = {};
      const browsers: { [key: string]: number } = {};
      const os: { [key: string]: number } = {};
      const trafficSources: { [key: string]: number } = {
        direct: 0,
        search: 0,
        social: 0,
        other: 0
      };
      const referrers: { [key: string]: number } = {};

      visits.forEach(visit => {
        // Count traffic sources
        trafficSources[visit.referrer_type] = (trafficSources[visit.referrer_type] || 0) + 1;

        // Track referrers
        if (visit.referrer) {
          try {
            const domain = new URL(visit.referrer).hostname;
            referrers[domain] = (referrers[domain] || 0) + 1;
          } catch {}
        }

        // Other stats
        if (visit.location?.country) {
          countries[visit.location.country] = (countries[visit.location.country] || 0) + 1;
        }
        if (visit.device_info?.browser) {
          browsers[visit.device_info.browser] = (browsers[visit.device_info.browser] || 0) + 1;
        }
        if (visit.device_info?.os) {
          os[visit.device_info.os] = (os[visit.device_info.os] || 0) + 1;
        }
      });

      return {
        totalVisits: visits.length,
        uniqueVisitors: uniqueIPs.size,
        countries,
        browsers,
        os,
        trafficSources,
        topReferrers: referrers
      };
    } catch (error) {
      errorLogger.log(error as Error, 'VisitorAnalytics GetStats Error');
      throw error;
    }
  }
}