import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import PublicDebtView from './pages/PublicDebtView';
import LandingPage from './pages/LandingPage';
import SignupPage from './pages/SignupPage';
import NotFoundPage from './pages/NotFoundPage';
import AdminLayout from './pages/admin/AdminLayout';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminUsers from './pages/admin/UserManagement';
import AdminTransactions from './pages/admin/TransactionList';
import AdminTeam from './pages/admin/TeamMembers';
import AdminLogs from './pages/admin/ErrorLogs';
import AdminSettings from './pages/admin/Settings';
import { useAuth } from './contexts/AuthContext';
import { initDatabase } from './db';
import { trackPageView } from './utils/analytics';

export default function App() {
  const { user } = useAuth();

  useEffect(() => {
    const init = async () => {
      try {
        await initDatabase();
      } catch (err) {
        console.error('Failed to initialize database:', err);
      }
    };

    init();
  }, []);

  useEffect(() => {
    // Track initial page view
    trackPageView();

    // Track page views on route changes
    const handleRouteChange = () => {
      trackPageView();
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  // Check if user is admin
  const isAdmin = user?.role === 'admin' || user?.role === 'moderator';

  return (
    <Routes>
      {/* Admin Routes */}
      {isAdmin && (
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="users" element={<AdminUsers />} />
          <Route path="transactions" element={<AdminTransactions />} />
          <Route path="team" element={<AdminTeam />} />
          <Route path="logs" element={<AdminLogs />} />
          <Route path="settings" element={<AdminSettings />} />
        </Route>
      )}

      {/* Public Routes */}
      <Route path="/" element={
        isAdmin ? <Navigate to="/admin" replace /> :
        user ? <Navigate to="/dashboard" replace /> :
        <LandingPage />
      } />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/debt/:token" element={<PublicDebtView />} />

      {/* Protected Routes */}
      <Route path="/dashboard/*" element={
        isAdmin ? <Navigate to="/admin" replace /> :
        user ? <Dashboard /> :
        <Navigate to="/" replace />
      } />

      {/* 404 Route */}
      <Route path="/404" element={<NotFoundPage />} />
      
      {/* Catch all route - redirect to 404 */}
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}