import React, { useState, useEffect } from 'react';
import { errorLogger } from '../../utils/errorLogger';

interface ErrorLog {
  timestamp: string;
  context: string;
  errorName: string;
  message: string;
  stack: string;
}

interface ErrorLogGroup {
  date: string;
  logs: ErrorLog[];
}

export default function ErrorLogs() {
  const [logs, setLogs] = useState<ErrorLogGroup[]>([]);
  const [selectedLog, setSelectedLog] = useState<ErrorLog | null>(null);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    loadLogs();
  }, []);

  const loadLogs = () => {
    const allLogs: ErrorLogGroup[] = [];
    const keys = Object.keys(localStorage);
    
    // Get all error log keys
    const logKeys = keys.filter(key => key.startsWith('error-log-'));
    
    // Sort keys in reverse chronological order
    logKeys.sort().reverse();

    // Process each day's logs
    for (const key of logKeys) {
      const logs = localStorage.getItem(key);
      if (!logs) continue;

      // Parse logs for this day
      const dayLogs: ErrorLog[] = logs.split('=== Error Entry ===')
        .filter(entry => entry.trim())
        .map(entry => {
          const lines = entry.trim().split('\n');
          const contextLine = lines.find(l => l.startsWith('Context:'))?.replace('Context:', '').trim() || '';
          const timestampLine = lines.find(l => l.startsWith('Timestamp:'))?.replace('Timestamp:', '').trim() || '';
          const errorNameLine = lines.find(l => l.startsWith('Error Name:'))?.replace('Error Name:', '').trim() || '';
          const messageLine = lines.find(l => l.startsWith('Message:'))?.replace('Message:', '').trim() || '';
          const stackStart = lines.findIndex(l => l.startsWith('Stack Trace:'));
          const stack = stackStart > -1 ? lines.slice(stackStart + 1).join('\n') : '';

          return {
            timestamp: timestampLine,
            context: contextLine,
            errorName: errorNameLine,
            message: messageLine,
            stack
          };
        });

      if (dayLogs.length > 0) {
        allLogs.push({
          date: key.replace('error-log-', ''),
          logs: dayLogs
        });
      }
    }

    setLogs(allLogs);
  };

  const handleClearLogs = () => {
    if (window.confirm('Are you sure you want to clear all error logs?')) {
      const keys = Object.keys(localStorage);
      const logKeys = keys.filter(key => key.startsWith('error-log-'));
      logKeys.forEach(key => localStorage.removeItem(key));
      loadLogs();
    }
  };

  const handleDownloadLogs = () => {
    errorLogger.downloadLogs();
  };

  const filteredLogs = logs.map(group => ({
    ...group,
    logs: group.logs.filter(log => 
      log.context.toLowerCase().includes(filter.toLowerCase()) ||
      log.message.toLowerCase().includes(filter.toLowerCase()) ||
      log.errorName.toLowerCase().includes(filter.toLowerCase())
    )
  })).filter(group => group.logs.length > 0);

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Error Logs</h1>
          <p className="mt-2 text-sm text-gray-700">
            View and manage system error logs.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-3">
          <button
            onClick={handleDownloadLogs}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          >
            Download Logs
          </button>
          <button
            onClick={handleClearLogs}
            className="inline-flex items-center justify-center rounded-md border border-red-600 bg-white px-4 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Clear Logs
          </button>
        </div>
      </div>

      <div className="flex justify-end">
        <div className="w-64">
          <input
            type="text"
            placeholder="Filter logs..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
          />
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {filteredLogs.map((group) => (
            <li key={group.date}>
              <div className="bg-gray-50 px-4 py-2">
                <h3 className="text-sm font-medium text-gray-900">
                  {new Date(group.date).toLocaleDateString()}
                </h3>
              </div>
              <ul className="divide-y divide-gray-200">
                {group.logs.map((log, index) => (
                  <li
                    key={index}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => setSelectedLog(log)}
                  >
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <p className="text-sm font-medium text-purple-600 truncate">
                            {log.context}
                          </p>
                          <div className="ml-2 flex-shrink-0">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                              {log.errorName}
                            </span>
                          </div>
                        </div>
                        <div className="ml-2 flex-shrink-0 text-sm text-gray-500">
                          {new Date(log.timestamp).toLocaleTimeString()}
                        </div>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-600">
                          {log.message}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>

      {/* Error Details Modal */}
      {selectedLog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start">
                <h3 className="text-lg font-medium text-gray-900">
                  Error Details
                </h3>
                <button
                  onClick={() => setSelectedLog(null)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <div className="mt-4 space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Context</h4>
                  <p className="mt-1 text-sm text-gray-900">{selectedLog.context}</p>
                </div>
                
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Timestamp</h4>
                  <p className="mt-1 text-sm text-gray-900">{selectedLog.timestamp}</p>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500">Error Type</h4>
                  <p className="mt-1 text-sm text-gray-900">{selectedLog.errorName}</p>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500">Message</h4>
                  <p className="mt-1 text-sm text-gray-900">{selectedLog.message}</p>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500">Stack Trace</h4>
                  <pre className="mt-1 text-sm text-gray-900 bg-gray-50 p-4 rounded-lg overflow-x-auto">
                    {selectedLog.stack}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}