import React, { useState } from 'react';
import BasicInfoSection from './signup/BasicInfoSection';
import DetailedInfoSection from './signup/DetailedInfoSection';
import { FormData } from './signup/types';
import { useFormValidation } from './signup/useFormValidation';
import UserModel from '../db/models/User';
import VerificationCodeModel from '../db/models/VerificationCode';
import { emailService } from '../services/EmailService';
import { errorLogger } from '../utils/errorLogger';

interface SignupFormProps {
  onSignupComplete: (email: string) => void;
}

export default function SignupForm({ onSignupComplete }: SignupFormProps) {
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    fullName: '',
    countryOfResidence: '',
    nationality: '',
    birthdate: ''
  });

  const [currentSection, setCurrentSection] = useState<'basic' | 'details'>('basic');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [showResendOption, setShowResendOption] = useState(false);
  const { errors, validate, setErrors } = useFormValidation(formData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    try {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      // Clear error when field is modified
      if (errors[name]) {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[name];
          return newErrors;
        });
      }
    } catch (error) {
      errorLogger.log(error as Error, 'SignupForm handleChange');
    }
  };

  const handleResendVerification = async () => {
    setIsSubmitting(true);
    setSubmitError(null);
    try {
      const user = await UserModel.findByEmail(formData.email);
      if (!user) {
        setSubmitError('User not found');
        return;
      }

      const verificationCode = await VerificationCodeModel.generate(user.id, 'email');
      await emailService.sendVerificationEmail(user.email, verificationCode);
      onSignupComplete(user.email);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to resend verification email';
      setSubmitError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    setSubmitError(null);
    setShowResendOption(false);

    try {
      // Create user
      const hashedPassword = btoa(formData.password); // Simple hash for demo
      const user = await UserModel.create({
        email: formData.email,
        password_hash: hashedPassword,
        full_name: formData.fullName,
        phone_number: formData.phoneNumber,
        country_of_residence: formData.countryOfResidence,
        nationality: formData.nationality,
        birthdate: formData.birthdate
      });

      // Generate verification code
      const verificationCode = await VerificationCodeModel.generate(user.id, 'email');

      // Send verification email
      await emailService.sendVerificationEmail(user.email, verificationCode);

      // Show success and redirect
      onSignupComplete(formData.email);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to create account';
      setSubmitError(errorMessage);
      if (errorMessage === 'Email already registered') {
        setShowResendOption(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextSection = (e: React.FormEvent) => {
    e.preventDefault();
    const basicFieldsValid = !errors.email && !errors.password && !errors.confirmPassword;
    if (formData.email && formData.password && formData.confirmPassword && basicFieldsValid) {
      setCurrentSection('details');
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-8 transform hover:scale-105 transition-transform duration-300">
        <div className="inline-block animate-bounce">
          🎉
        </div>
        <h1 className="text-4xl font-bold text-white mb-4">
          Congratulations!
        </h1>
        <p className="text-xl text-white/90">
          You're just one step away from joining the REPAYE community
        </p>
        <p className="text-md text-white/80 mt-2">
          To join our community, please complete the registration process below
        </p>
      </div>

      <div className="bg-white/10 backdrop-blur-md p-1 rounded-2xl shadow-2xl">
        <div className="bg-white rounded-xl p-8">
          <div className="flex justify-center mb-8">
            <div className="flex items-center">
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                currentSection === 'basic' ? 'bg-purple-600 text-white' : 'bg-purple-100 text-purple-600'
              }`}>
                1
              </div>
              <div className="w-20 h-1 bg-purple-100">
                <div className={`h-full bg-purple-600 transition-all duration-500 ${
                  currentSection === 'details' ? 'w-full' : 'w-0'
                }`} />
              </div>
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                currentSection === 'details' ? 'bg-purple-600 text-white' : 'bg-purple-100 text-purple-600'
              }`}>
                2
              </div>
            </div>
          </div>

          {submitError && (
            <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p>{submitError}</p>
              {showResendOption && (
                <button
                  onClick={handleResendVerification}
                  disabled={isSubmitting}
                  className="mt-2 text-purple-600 hover:text-purple-700 font-medium disabled:opacity-50"
                >
                  {isSubmitting ? 'Sending...' : 'Resend verification email →'}
                </button>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {currentSection === 'basic' ? (
              <BasicInfoSection
                formData={formData}
                errors={errors}
                onChange={handleChange}
                onNext={handleNextSection}
              />
            ) : (
              <DetailedInfoSection
                formData={formData}
                errors={errors}
                onChange={handleChange}
                onBack={() => setCurrentSection('basic')}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}