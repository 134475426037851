import { useState } from 'react';
import { FormData, ValidationErrors } from './types';

export function useFormValidation(formData: FormData) {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validate = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (!formData.phoneNumber.match(/^\+?[\d\s-]{8,}$/)) {
      newErrors.phoneNumber = 'Please enter a valid phone number';
    }

    if (formData.fullName.length < 2) {
      newErrors.fullName = 'Please enter your full name';
    }

    if (!formData.countryOfResidence) {
      newErrors.countryOfResidence = 'Please select your country of residence';
    }

    if (!formData.nationality) {
      newErrors.nationality = 'Please select your nationality';
    }

    const birthDate = new Date(formData.birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }

    if (!formData.birthdate || age < 18) {
      newErrors.birthdate = 'You must be at least 18 years old';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return { errors, validate, setErrors };
}