class ErrorLogger {
  private logPrefix = 'error-log';

  private formatError(error: Error): string {
    return `
Timestamp: ${new Date().toISOString()}
Error Name: ${error.name}
Message: ${error.message}
Stack Trace:
${error.stack}
-----------------
`;
  }

  private getLogKey(): string {
    const date = new Date();
    return `${this.logPrefix}-${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  public log(error: Error, context: string = ''): void {
    const logKey = this.getLogKey();
    const errorEntry = `
=== Error Entry ===
Context: ${context}
${this.formatError(error)}
`;

    // Get existing logs for today
    const existingLogs = localStorage.getItem(logKey) || '';
    
    // Append new error
    localStorage.setItem(logKey, existingLogs + errorEntry);

    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error logged:', {
        context,
        error,
        logKey
      });
    }
  }

  public clearOldLogs(daysToKeep: number = 30): void {
    const now = new Date();
    const keys = Object.keys(localStorage);
    
    keys.forEach(key => {
      if (key.startsWith(this.logPrefix)) {
        const datePart = key.split('-').slice(2).join('-');
        const logDate = new Date(datePart);
        const daysOld = (now.getTime() - logDate.getTime()) / (1000 * 60 * 60 * 24);

        if (daysOld > daysToKeep) {
          localStorage.removeItem(key);
        }
      }
    });
  }

  public downloadLogs(): void {
    const keys = Object.keys(localStorage);
    let allLogs = '';

    keys.forEach(key => {
      if (key.startsWith(this.logPrefix)) {
        const logs = localStorage.getItem(key);
        if (logs) {
          allLogs += `\n=== ${key} ===\n${logs}`;
        }
      }
    });

    const blob = new Blob([allLogs], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `error-logs-${new Date().toISOString().split('T')[0]}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}

export const errorLogger = new ErrorLogger();