import React, { useRef, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { QRCodeSVG } from 'qrcode.react';

interface QRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  debtUrl: string;
}

export default function QRCodeModal({ isOpen, onClose, debtUrl }: QRCodeModalProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (isOpen) {
      renderQRCodeToCanvas();
    }
  }, [isOpen, debtUrl]);

  const renderQRCodeToCanvas = () => {
    const svg = document.getElementById('debt-qr-code');
    if (svg && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Set canvas size with padding for text and border
      canvas.width = 400;  // Increased size to accommodate text and border
      canvas.height = 450;
      
      // Fill white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw title text
      ctx.fillStyle = '#9333ea'; // Purple color
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('You owe me!', canvas.width / 2, 40);
      ctx.fillText('Scan here to make it right 😜', canvas.width / 2, 70);

      // Draw purple border
      ctx.strokeStyle = '#9333ea';
      ctx.lineWidth = 8;
      ctx.roundRect(50, 90, 300, 300, 15);
      ctx.stroke();

      // Convert QR code SVG to image
      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      img.onload = () => {
        // Draw QR code in the center of the border
        ctx.drawImage(img, 75, 115, 250, 250);

        // Draw R logo in the center
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(canvas.width / 2, 240, 20, 0, Math.PI * 2);
        ctx.fill();
        
        ctx.fillStyle = '#9333ea';
        ctx.font = 'bold 24px Arial';
        ctx.fillText('R', canvas.width / 2, 248);

        // Draw footer text
        ctx.fillStyle = '#6B7280'; // Gray color
        ctx.font = '16px Arial';
        ctx.fillText('repaye.com', canvas.width / 2, 420);
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  const handleDownload = () => {
    if (canvasRef.current) {
      const pngFile = canvasRef.current.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'repaye-debt-qr.png';
      downloadLink.href = pngFile;
      downloadLink.click();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm w-full rounded-2xl bg-white p-8 shadow-2xl">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-bold text-gray-900">
              Share via QR Code
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="flex flex-col items-center space-y-6">
            {/* Hidden QR Code SVG for reference */}
            <div className="hidden">
              <QRCodeSVG
                id="debt-qr-code"
                value={debtUrl}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>

            {/* Canvas for combined image */}
            <canvas
              ref={canvasRef}
              className="max-w-full h-auto"
            />

            <div className="text-center">
              <p className="text-sm text-gray-500 mb-2">
                Scan this QR code to view the debt details
              </p>
              <div className="flex flex-col space-y-2">
                <button
                  onClick={handleDownload}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Download QR Code
                </button>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(debtUrl);
                  }}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Copy Link
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}