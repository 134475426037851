import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';
import { DebtRequestModel, type DebtRequest } from '../../db/models/DebtRequest';

export default function History() {
  const { user } = useAuth();
  const [history, setHistory] = useState<DebtRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadHistory = async () => {
      if (!user) return;
      
      try {
        setIsLoading(true);
        const debts = await DebtRequestModel.findByCreditor(user.id);
        // Sort by created_at in descending order
        const sortedDebts = debts.sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setHistory(sortedDebts);
      } catch (error) {
        console.error('Error loading debt history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadHistory();
  }, [user]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (history.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6 text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">No History Yet</h2>
        <p className="text-gray-600">
          You haven't created any debt requests yet. Start by creating your first one!
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Debt History</h2>
        
        <div className="space-y-4">
          {history.map((item) => (
            <div 
              key={item.id}
              className="border-l-4 border-l-purple-500 bg-white p-4 rounded-r-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold text-lg text-gray-800">
                    {item.debtor_name || 'Unknown Debtor'}
                  </h3>
                  <p className="text-gray-600">{item.description}</p>
                  {item.amount > 0 && (
                    <p className="text-purple-600 font-medium mt-1">
                      Amount: ${item.amount.toFixed(2)}
                    </p>
                  )}
                  <div className="mt-2 text-sm text-gray-500">
                    <span>Created: {new Date(item.created_at).toLocaleDateString()}</span>
                    {item.status === 'settled' && (
                      <span> • Settled: {new Date(item.updated_at).toLocaleDateString()}</span>
                    )}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">
                    Category: {item.category} - {item.subcategory}
                  </div>
                </div>
                <div className="flex items-center">
                  {item.status === 'settled' ? (
                    <span className="flex items-center text-green-600">
                      <CheckCircleIcon className="h-6 w-6 mr-2" />
                      Settled
                    </span>
                  ) : item.status === 'cancelled' ? (
                    <span className="flex items-center text-red-600">
                      <XCircleIcon className="h-6 w-6 mr-2" />
                      Cancelled
                    </span>
                  ) : (
                    <span className="flex items-center text-yellow-600">
                      <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Pending
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}