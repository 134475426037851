import React, { useState, useEffect } from 'react';

interface FacebookShareProps {
  debtData: any;
  onShareComplete: () => void;
}

export default function FacebookShare({ debtData, onShareComplete }: FacebookShareProps) {
  const [shareStatus, setShareStatus] = useState({
    facebook: false,
    twitter: false,
    instagram: false,
    tiktok: false,
    discord: false,
    copyLink: false
  });

  const [copySuccess, setCopySuccess] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const sharedCount = Object.values(shareStatus).filter(Boolean).length;
    if (sharedCount >= 2 && !isCompleted) {
      setIsCompleted(true);
      // Wait for 2 seconds to show the completion message
      setTimeout(onShareComplete, 2000);
    }
  }, [shareStatus, onShareComplete, isCompleted]);

  const handleShare = async (platform: keyof typeof shareStatus) => {
    const shareUrl = 'https://repaye.com';
    let url = '';

    if (platform === 'copyLink') {
      try {
        await navigator.clipboard.writeText(`${customMessage}\n\n${shareUrl}`);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
        setShareStatus(prev => ({
          ...prev,
          [platform]: true
        }));
        return;
      } catch (err) {
        alert('Failed to copy link. Please try again.');
        return;
      }
    }

    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(customMessage)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(customMessage)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'tiktok':
        url = `https://www.tiktok.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(customMessage)}`;
        break;
      case 'instagram':
        navigator.clipboard.writeText(customMessage + '\n\n' + shareUrl);
        alert('Message copied! Open Instagram and paste to share.');
        setShareStatus(prev => ({
          ...prev,
          [platform]: true
        }));
        return;
      case 'discord':
        url = `https://discord.com/api/oauth2/authorize?client_id=YOUR_CLIENT_ID&permissions=2048&scope=bot%20applications.commands`;
        break;
    }

    const shareWindow = window.open(url, '_blank', 'width=600,height=400');
    
    const timer = setInterval(() => {
      if (shareWindow?.closed) {
        clearInterval(timer);
        setShareStatus(prev => ({
          ...prev,
          [platform]: true
        }));
      }
    }, 1000);
  };

  const [customMessage, setCustomMessage] = useState(
    "🚨 DEBT ALERT! 🚨\n\n" +
    "Someone you know might owe you something! 🤔\n" +
    "Don't let those IOUs slip away!\n\n" +
    "Join me on Repaye - where we turn awkward money talks into fun memories! 😌\n\n" +
    "➡️ Track forgotten debts\n" +
    "➡️ Send friendly reminders\n" +
    "➡️ Keep friendships strong\n\n" +
    "#SettleTheDebt #DebtFree #FriendlyReminder"
  );

  return (
    <div className="bg-white rounded-lg shadow-xl p-8 text-center">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        One Last Step! 🎉
      </h2>
      <p className="text-gray-600 mb-6">
        Share on at least 2 platforms to continue!
      </p>

      <div className="mb-6">
        <textarea
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
          rows={8}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <button
          onClick={() => handleShare('facebook')}
          className={`${
            shareStatus.facebook 
              ? 'bg-green-600 hover:bg-green-700' 
              : 'bg-blue-600 hover:bg-blue-700'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
          </svg>
          <span>{shareStatus.facebook ? 'Shared ✓' : 'Facebook'}</span>
        </button>

        <button
          onClick={() => handleShare('twitter')}
          className={`${
            shareStatus.twitter
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-black hover:bg-gray-800'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
          </svg>
          <span>{shareStatus.twitter ? 'Shared ✓' : 'X (Twitter)'}</span>
        </button>

        <button
          onClick={() => handleShare('instagram')}
          className={`${
            shareStatus.instagram
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-gradient-to-r from-purple-600 via-pink-600 to-orange-500 hover:opacity-90'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.897 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.897-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
          </svg>
          <span>{shareStatus.instagram ? 'Shared ✓' : 'Instagram'}</span>
        </button>

        <button
          onClick={() => handleShare('tiktok')}
          className={`${
            shareStatus.tiktok
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-[#010101] hover:bg-gray-900'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/>
          </svg>
          <span>{shareStatus.tiktok ? 'Shared ✓' : 'TikTok'}</span>
        </button>

        <button
          onClick={() => handleShare('discord')}
          className={`${
            shareStatus.discord
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-[#5865F2] hover:bg-[#4752C4]'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/>
          </svg>
          <span>{shareStatus.discord ? 'Shared ✓' : 'Discord'}</span>
        </button>

        <button
          onClick={() => handleShare('copyLink')}
          className={`${
            shareStatus.copyLink
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-gray-600 hover:bg-gray-700'
          } text-white font-bold py-3 px-6 rounded-md transition duration-200 cursor-pointer flex items-center justify-center space-x-2`}
        >
          <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path d="M7.024 3.75c0-.966.784-1.75 1.75-1.75H20.25c.966 0 1.75.784 1.75 1.75v11.498a1.75 1.75 0 0 1-1.75 1.75H8.774a1.75 1.75 0 0 1-1.75-1.75V3.75zm1.75-.25a.25.25 0 0 0-.25.25v11.498c0 .139.112.25.25.25H20.25a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25H8.774z"/>
            <path d="M1.995 9.75c0-.966.784-1.75 1.75-1.75h.5a.75.75 0 0 1 0 1.5h-.5a.25.25 0 0 0-.25.25v11.498c0 .139.112.25.25.25h11.476a.25.25 0 0 0 .25-.25v-.5a.75.75 0 0 1 1.5 0v.5a1.75 1.75 0 0 1-1.75 1.75H3.745a1.75 1.75 0 0 1-1.75-1.75V9.75z"/>
          </svg>
          <span>{copySuccess ? 'Copied! ✓' : shareStatus.copyLink ? 'Shared ✓' : 'Copy Link'}</span>
        </button>
      </div>

      <div className="mt-8">
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-purple-600 h-2 rounded-full transition-all duration-500"
            style={{ 
              width: `${(Object.values(shareStatus).filter(Boolean).length / 2) * 100}%` 
            }}
          />
        </div>
        <p className="text-sm text-gray-600 mt-2">
          {isCompleted 
            ? '✨ All done! Moving to next step...' 
            : `Share on ${2 - Object.values(shareStatus).filter(Boolean).length} more platforms to continue`}
        </p>
      </div>
    </div>
  );
}