import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debtCategories } from '../../data/debtCategories';
import { useAuth } from '../../contexts/AuthContext';
import { DebtRequestModel } from '../../db/models/DebtRequest';
import type { DebtRequestCreate } from '../../db/models/DebtRequest';
import { errorLogger } from '../../utils/errorLogger';
import QRCodeModal from '../QRCodeModal';

interface DebtForm {
  category: string;
  subcategory: string;
  description: string;
  visibility: 'private' | 'public';
  amount: number;
  debtor: {
    email?: string;
    phone?: string;
    fullName?: string;
    social: {
      facebook?: string;
      instagram?: string;
      twitter?: string;
      tiktok?: string;
    };
  };
}

export default function NewDebtRequest() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [debtUrl, setDebtUrl] = useState('');
  const [request, setRequest] = useState<DebtForm>({
    category: '',
    subcategory: '',
    description: '',
    visibility: 'private',
    amount: 0,
    debtor: {
      social: {}
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a debt request');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);

    try {
      const debtRequest: DebtRequestCreate = {
        creditor_id: user.id,
        debtor_name: request.debtor.fullName || '',
        debtor_email: request.debtor.email,
        debtor_phone: request.debtor.phone,
        amount: request.category === 'Personal Loans' ? request.amount : 0,
        description: request.description,
        category: request.category,
        subcategory: request.subcategory,
        social_links: Object.keys(request.debtor.social).length > 0 
          ? JSON.stringify(request.debtor.social)
          : undefined,
        visibility: request.visibility
      };

      const createdDebt = await DebtRequestModel.create(debtRequest);
      const url = `${window.location.origin}/d/${createdDebt.public_token}`;
      setDebtUrl(url);
      setShowQRCode(true);
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to create debt request';
      setError(errorMessage);
      errorLogger.log(error as Error, 'DebtRequest Create Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDebtorChange = (field: string, value: string) => {
    setRequest(prev => ({
      ...prev,
      debtor: {
        ...prev.debtor,
        [field]: value
      }
    }));
  };

  const handleSocialChange = (platform: string, value: string) => {
    setRequest(prev => ({
      ...prev,
      debtor: {
        ...prev.debtor,
        social: {
          ...prev.debtor.social,
          [platform]: value
        }
      }
    }));
  };

  const showAmountField = request.category === 'Personal Loans' && request.subcategory;

  return (
    <div className="space-y-6">
      {showSuccess && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg z-50 animate-bounce">
          Debt request created successfully! 🎉
        </div>
      )}

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">New Debt Request</h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Rest of the form fields remain the same */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Category
            </label>
            <select
              value={request.category}
              onChange={(e) => setRequest({ ...request, category: e.target.value, subcategory: '', amount: 0 })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              required
            >
              <option value="">Select a category</option>
              {Object.entries(debtCategories).map(([category, data]) => (
                <option key={category} value={category}>
                  {data.icon} {category}
                </option>
              ))}
            </select>
          </div>

          {request.category && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Subcategory
              </label>
              <select
                value={request.subcategory}
                onChange={(e) => setRequest({ ...request, subcategory: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
                required
              >
                <option value="">Select a subcategory</option>
                {debtCategories[request.category].subcategories.map((sub) => (
                  <option key={sub.name} value={sub.name}>
                    {sub.icon} {sub.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {showAmountField && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Amount
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={request.amount}
                  onChange={(e) => setRequest({ ...request, amount: parseFloat(e.target.value) })}
                  className="focus:ring-purple-500 focus:border-purple-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                  required={true}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">USD</span>
                </div>
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Description
            </label>
            <textarea
              value={request.description}
              onChange={(e) => setRequest({ ...request, description: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              rows={4}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Debtor Information
            </label>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Full Name"
                value={request.debtor.fullName || ''}
                onChange={(e) => handleDebtorChange('fullName', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
              <input
                type="email"
                placeholder="Email"
                value={request.debtor.email || ''}
                onChange={(e) => handleDebtorChange('email', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
              <input
                type="tel"
                placeholder="Phone"
                value={request.debtor.phone || ''}
                onChange={(e) => handleDebtorChange('phone', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Social Media Links
            </label>
            <div className="space-y-4">
              <input
                type="url"
                placeholder="Facebook Profile URL"
                value={request.debtor.social.facebook || ''}
                onChange={(e) => handleSocialChange('facebook', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
              <input
                type="url"
                placeholder="Instagram Profile URL"
                value={request.debtor.social.instagram || ''}
                onChange={(e) => handleSocialChange('instagram', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
              <input
                type="url"
                placeholder="Twitter Profile URL"
                value={request.debtor.social.twitter || ''}
                onChange={(e) => handleSocialChange('twitter', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
              <input
                type="url"
                placeholder="TikTok Profile URL"
                value={request.debtor.social.tiktok || ''}
                onChange={(e) => handleSocialChange('tiktok', e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Visibility
            </label>
            <select
              value={request.visibility}
              onChange={(e) => setRequest({ ...request, visibility: e.target.value as 'private' | 'public' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500"
            >
              <option value="private">Private</option>
              <option value="public">Public</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-purple-600 text-white py-3 px-4 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Creating...' : 'Create Debt Request'}
          </button>
        </form>
      </div>

      <QRCodeModal
        isOpen={showQRCode}
        onClose={() => setShowQRCode(false)}
        debtUrl={debtUrl}
      />
    </div>
  );
}